import React from 'react'
import { Link } from "react-router-dom";

function Box2({color}) {
  return (
    <div className='box2'>
      <div className='bold'>Resources</div>
      <div className='all'>
        <div>
          <Link to='/about' className='textWhite' style={{color:color}}> About Us </Link>
        </div>
        <div>
          <Link to="/Blog" className='textWhite' style={{color:color}}> Blog </Link>
        </div>
        <div className='partner'>
          <a className='textWhite' style={{color:color}}> Partners </a>
        </div>
        <div>
          <Link to='/contact' className='textWhite' style={{color:color}}>Contact Us</Link>
        </div>
      </div>
    </div>
  )
}

export default Box2