import React, { useState } from 'react'
import logo from '../../Images/logo.svg';

function Box1() {

    const [formData, setFormData] = useState({ email: '' });
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const onSubmit = async (e) => {
        e.preventDefault()
        const api = "2789E8555DDDAEE29F4CEF00700FB8AD223E6E869E703A3E7214AED132A6CB80B1631AB07886218272EDFC4CC7A0D763";
        const senderEmail = "support@kognics.com"
        const ReceverEmail = "siddiq@kognics.com,dm@kognics.com,vishal@kognics.com";
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };
        const message = `Email: ${formData.email}`;

        try {
            const res = await fetch(`https://api.elasticemail.com/v2/email/send?apikey=${api}&subject=Lead Generated For Kognics &from=${senderEmail}&to=${ReceverEmail}&bodyHtml=${message}`, requestOptions)
            if (res.status === 200) {
                if (alert("Thank You, We will get in touch with you shortly") == null) {
                    setFormData({ email: '' })
                }
            }
        } catch (e) {
            alert(e)
        }
    }
    return (
        <div className='box1'>
            <img className='logo' src={logo} alt='logo' />
            <div className='desc'>We build products that help people to succeed.</div>
            <div className='label-input'>
                <div className='label'>Enter your email & get details of your query</div>
                <form className='input-container' onSubmit={onSubmit}>
                    <input style={{ borderRadius: "50px", background: "white", boxShadow: "0px 0px 8px 0px #3ECBB1" }} type='email' name='email' value={formData.email} onChange={handleChange} required />
                    <button style={{ borderRadius: "50px", background: "#1C5466", boxShadow: "0px 0px 8px 0px #1C5466" }}>Send</button>
                </form>
            </div>
        </div>
    )
}

export default Box1