import React from 'react'

const AboutRowContainer = ({ title, para, img, style, PopUp, arrowImg }) => {


    return (
        <>
            <div className='about-section-container' style={{ background: style ? style.backgroundColor : '' }}>
                <div className='about-section'>

                    <div className='about-row' style={{ flexDirection: style ? style.flexDirection : '' }}>
                        <div className='about-col'>
                            <div className='leftSide'>
                                <h2 style={{
                                    textAlign: style ? style.textAlign : '',
                                    paddingRight: style ? style.PaddingRight : '',
                                    color: style ? style.color : ''
                                }}>{title}</h2>
                                <p style={{
                                    textAlign: style ? style.textAlign : '',
                                    paddingRight: style ? style.PaddingRight : '',
                                    color: style ? style.color : ''
                                }}>{para}</p>
                                {PopUp}
                                <img src={img} alt='course' width="100%" height="100%" className='courserHome-mobile' />
                            </div>
                        </div>
                        <div className='about-col'>
                            <div className='rightSide'>
                                <img src={img} alt='course' width="100%" height="100%" />
                            </div>
                            <div className='rightSide-mobile'>
                                <p>{para}</p>
                                {PopUp}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ArrowImg'>
                    <img src={arrowImg} alt="arrow" width="15%" height="15%" />
                </div>
            </div>
        </>
    )
}

export default AboutRowContainer