import React from "react";
import Header from "../../Header/Navbar";
import Footer from "../../Footer/Footer/Footer";
import desklearn from "../RLearningPath/imgs/desklearn.svg";
import moblearn from "../RLearningPath/imgs/moblearn.svg";
import desktrain from "../RLearningPath/imgs/desktrain.svg";
import mobtrain from "../RLearningPath/imgs/mobtrain.svg";
import dotlinelearn from "../RLearningPath/imgs/dotline-learn.svg";
import "./learning.css";
import "./learning2.css";

export default function LearningPath() {
  return (
    <>
      <div className="border">
        <Header />
      </div>

      <div className="learn-rectangle">
        <div className="learn-hd">
          <h1>Learning Path</h1>
        </div>

        <div className="create-learn">
          <div className="learnimg">
            <img src={moblearn} alt="" className="moblearn" />
            <img src={desklearn} alt="" className="desklearn" />
          </div>
        </div>

        <div className="empowered">
          <h1>
            <span className="bold"></span> With Guided Learning, help your
            employees visualise their growth.
          </h1>
        </div>

        <div className="three-card-learning">
          <div class="twocard-learning">
            <div class="block-learning">
              <h1 className="cardheadings-learning">
                Guided learning helps create a vision for growth
              </h1>
              <img src={dotlinelearn} alt="p" className="dotline-learn" />
              <p className="paragra-learn">
                With right tools and guided learning through kognics learning
                path, your employees will be able to see long term growth and
                help you improve retention
              </p>
            </div>

            <div class="block-learning">
              <h1 className="cardheadings-learning">
                Don’t let your onboarding become on-boring.
              </h1>
              <img src={dotlinelearn} alt="p" className="dotline-learn" />
              <p className="paragra-learn">
                You know your onboarding isn’t as strong as it should be, but
                there’s limited time to fix it or address training gaps.{" "}
                <br></br> Kognics helps you to overcome this problem by
                providing interesting and guided learning
              </p>
            </div>
          </div>

          <div class="twocard-learning">
            <div class="block-learning">
              <h1 className="cardheadings-learning">
                Desperate system creates confusion.
              </h1>
              <img src={dotlinelearn} alt="p" className="dotline-learn" />
              <p className="paragra-learn">
                Your agent jumps from software to software while they’re serving
                a customer because they can’t find the information they need
                quickly. <br></br> With Learning Paths, all their right data is
                in the right places
              </p>
            </div>
          </div>
        </div>

        <div className="better">
          <h1>
            With Kognics, <span className="boldd">Better</span> customer
            experiences are possible.
          </h1>
        </div>

        <div className="learning-train">
          <div className="learningtrainimg">
            <img src={desktrain} alt="" className="desktrain" />
            <img src={mobtrain} alt="" className="mobtrain" />
          </div>
        </div>

        <div className="last-learning"></div>

        <Footer color="#1C5466" backgroundColor="#D6F5FF" />
      </div>
    </>
  );
}
