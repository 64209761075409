import { Button } from 'bootstrap'
import React from 'react'
import { useState } from 'react'
import { Card } from 'react-bootstrap'
import Popup from './popUp/popUp'
const PricingCard = ({ arr, title, NoUser, Frequency, PricePerUser, img, price, priceYear, btnColor, id, bgColor, color }) => {

    return (
        <div className='PricingCard' >
            <div className='pricingCard-content'>
                {/* <img src={bluePrint} alt='crad' width="100%" /> */}
                <div className='pricing-content' id={id} style={{
                    background: `${bgColor}`,
                }}>
                    <div className='pricing-inner-content' style={{ color: color }}>
                        <h1 style={{ borderBottom: `2px solid ${color}` }}>{title}</h1>
                        <p style={{ lineHeight: title === "Blueprint" ? "55px" : "45px" }}>{NoUser}</p>
                        {
                            Frequency === "Quarterly" || Frequency === "Yearly" && title !== "Blueprint" ?
                                <p className='w-100' style={{ lineHeight: title === "Blueprint" ? "55px" : "45px" }} >Price Per User: <i class="fa fa-inr"></i> {PricePerUser}</p> : null
                        }
                        <p style={{ lineHeight: title === "Blueprint" ? "55px" : "45px" }}>GST: Included</p>
                        <p style={{ lineHeight: title === "Blueprint" ? "55px" : "45px" }}>Frequency: {Frequency}</p>
                        {
                            title === "Blueprint" ?
                                <p className='mt-2' style={{ lineHeight: title === "Blueprint" ? "55px" : "45px" }}>
                                    <span className='amount'><i class="fa fa-inr"></i>
                                        {priceYear}</span> / Year
                                </p>
                                : <p style={{ lineHeight: title === "Blueprint" ? "55px" : "45px" }}>Price(INR): Based on Users</p>
                        }
                        <Popup
                            btnColor={btnColor}
                            id={id}
                            title={title}
                            arr={arr}
                        />
                        {/* <div className='pt-3'>

                            <button className='buybtn' id={id} style={{ backgroundColor: btnColor }}>
                                Buy
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PricingCard