
import { useState } from 'react';
import axios from 'axios';

const useBlogPosts = () => {

    const [error, setError] = useState(null);

    const ImageId = async (id) => {
        try {
            const response = await axios.get(`https://blog.kognics.com/wp-json/wp/v2/media/${id}`);
            return response.data
        } catch (error) {
            setError(error.response.data.error);
        }
    };

    const singlePost = async (id) => {
        try {
            const response = await axios.get(`https://blog.kognics.com/wp-json/wp/v2/posts?slug=${id}`);
            return response.data
        } catch (error) {
            return error.response.data.error;
        }
    };

    const UpdateContent = (content) => {

        var tempContainer = document.createElement('div');
        tempContainer.innerHTML = content;
        // Select the image element
        var imgElement = tempContainer.querySelectorAll('img');

        // Apply styles to the image element
        imgElement.forEach((content) => {
            content.style.width = '100%';
            content.style.height = 'auto';
            content.style.transform = "scale(0.9)"
        })
        // Get the modified HTML content
        var modifiedHtmlContent = tempContainer.innerHTML;
        return modifiedHtmlContent
    }

    return { ImageId, singlePost, UpdateContent, error };
};

export default useBlogPosts;
