
import axios from 'axios';

const useEmailSender = () => {

    const mailsender = async (data, setDisplay) => {
        const getdata = (data) => {
            const filteredObj = Object.entries(data)
                .filter(([key, value]) => value !== "")
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});


            let htmlBody = '<html><body>';

            if ("Fname" in filteredObj && "Lname" in filteredObj && "email" in filteredObj) {
                htmlBody += `<p><strong>First Name:</strong> ${filteredObj["Fname"]}</p>`;
                htmlBody += `<p><strong>Last Name:</strong> ${filteredObj["Lname"]}</p>`;
                htmlBody += `<p><strong>Email:</strong> ${filteredObj["email"]}</p>`;
            } else if ("Fname" in filteredObj && "email" in filteredObj) {
                htmlBody += `<p><strong>Name:</strong> ${filteredObj["Fname"]}</p>`;
                htmlBody += `<p><strong>Email:</strong> ${filteredObj["email"]}</p>`;
            }else if("email" in filteredObj){
                htmlBody += `<p><strong>Email:</strong> ${filteredObj["email"]}</p>`;
            }

            htmlBody += '</body></html>';

            return htmlBody;
        };

        try {
            const response = await axios.post('https://backend.kognics.com/demo-client/send-mail', {
                "emails": ["siddiq@kognics.com", "dm@kognics.com", "vishal@kognics.com"],
                //"emails": ["vishal@kognics.com", "kkumar95973@gmail.com"],
                "subject": "Lead Generated  for kognics",
                "mailContent": getdata(data)
            });
            if (response.status === 200) {
                setDisplay(false)
            }
        } catch (error) {
            console.error('Error sending data:', error);
        }
    }

    return { mailsender };
};

export default useEmailSender;
