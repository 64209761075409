import React from 'react'
import { Link } from 'react-router-dom'


function Box4({ color }) {
  return (
    <div className='box4'>
      <div className='bold'>Get In Touch</div>
      <div className='all'>
        <div className='textWhite' style={{ color: color }}><i className="fa-solid fa-envelope"></i>&nbsp;&nbsp;&nbsp;&nbsp;dm@kognics.com</div>
        <div className='textWhite' style={{ color: color }}><i className="fa-sharp fa-solid fa-phone"></i>&nbsp;&nbsp;&nbsp;&nbsp;+91 7878106602</div>
        <div className='icons'>
          <a href="https://www.facebook.com/kognics/" className="textWhite" style={{ color: color, border: `1px solid ${color}` }}><div className='circle'><i className="fa-brands fa-facebook-f"></i></div></a>
          <a href='https://twitter.com/Kognics' className="textWhite" style={{ color: color, border: `1px solid ${color}` }}><div className='circle'><i className="fa-brands fa-twitter"></i></div></a>
          <a href='https://www.instagram.com/kognics/' className="textWhite" style={{ color: color, border: `1px solid ${color}` }}><div className='circle'><i className="fa-brands fa-instagram"></i></div></a>
          <a href='https://www.linkedin.com/company/kognics/' className="textWhite" style={{ color: color, border: `1px solid ${color}` }}><div className='circle'><i className="fa-brands fa-linkedin-in"></i></div></a>
        </div>
      </div>
    </div>
  )
}

export default Box4