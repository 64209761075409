import React from 'react'
import { Link } from 'react-router-dom'
import crouseDrop from "../Images/NewHome/dropDown/coursesdropDown.svg"
import LPath from "../Images/NewHome/dropDown/LearningPathDd.svg"
import Quizzess from "../Images/NewHome/dropDown/QuizzesDrop.svg"
import ProductHub from "../Images/NewHome/dropDown/ProductHubDD.svg"
import Feedback from "../Images/NewHome/dropDown/Feedbackdd.svg"
import ContentLibrary from "../Images/NewHome/dropDown/ContentLibraryDD.svg"
import blog from "../Images/NewHome/dropDown/blog.svg"
import FoxImg from "../Images/NewHome/dropDown/dropDownFox.svg"
import { Row, Col, Container } from 'react-bootstrap'

const dropdown = ({ page }) => {
    return (
        <div className='dropdown-container'>
            <div className='DropDownCard'>
                <div className='content_items'>
                    <div className='content_item  sameBox'>
                        <div className='content-heading'>Explore the Platform</div>
                        <div className='list_drop'>
                            <ul style={{ width: '10vw' }}>
                                <li>
                                    <Link className={`${"finance" === page ? "Active_page" : "dropDown_textStyle"}`} to="/Courses">
                                        <img className='iconDropDown' src={crouseDrop} alt='dropDown' width="50px" height="50px" />
                                        Courses
                                    </Link>
                                </li>
                                <li>
                                    <Link className={`${"healthcare" === page ? "Active_page" : "dropDown_textStyle"}`} to="/Learning-Path">
                                        <img className='iconDropDown' src={LPath} alt='dropDown' width="50px" height="50px" />
                                        Learning Path
                                    </Link>
                                </li>
                                <li>
                                    <Link className={`${"manufacturing" === page ? "Active_page" : "dropDown_textStyle"}`} to="/Quizzess">
                                        <img className='iconDropDown' src={Quizzess} alt='dropDown' width="50px" height="50px" />
                                        Quizzes
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link className={`${"software" === page ? "Active_page" : "dropDown_textStyle"}`} to="/Product-Hub">
                                        <img className='iconDropDown' src={ProductHub} alt='dropDown' width="50px" height="50px" />
                                        Product Hub
                                    </Link>
                                </li> */}
                                <li>
                                    <Link className={`${"retail" === page ? "Active_page" : "dropDown_textStyle"}`} to="/Feedback">
                                        <img className='iconDropDown' src={Feedback} alt='dropDown' width="50px" height="50px" />
                                        Feedback
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='content_item sameBox middelContent'>
                        <div className='content-heading'>Resources</div>
                        <div className='list_drop'>
                            <ul style={{ width: '13vw' }}>
                                <li><Link className={`${"salesEnablement" === page ? "Active_page" : "dropDown_textStyle"}`} to="/Content-Library">
                                    <img className='iconDropDown' src={ContentLibrary} alt='dropDown' width="50px" height="50px" />
                                    Content Library
                                </Link>
                                </li>
                                <li>
                                    <Link className={`${"customerSuccessTeams" === page ? "Active_page" : "dropDown_textStyle"}`} to="/Blog">
                                        <img className='iconDropDown' src={blog} alt='dropDown' width="50px" height="50px" />
                                        Blog
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='content_item largeBox'>
                        <div className='contentBox'>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div className='rightSide'>
                                        <img src={FoxImg} alt='course' width="100%" height="100%" />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className='leftSide'>
                                        <h2>Hey there!</h2>
                                        <p>Let's team up and create
                                            kickass courses that make learning fun.
                                            So are you in ?
                                        </p>
                                        <div className='btn-singUp'>
                                            <p>
                                                <a href='https://signup.kognics.com/' target='_blank' style={{ textDecoration: "none", color: "white" }}>Sign up Today</a>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default dropdown