import React from 'react'
import "./feedback.css";
import dotlinefimg from "../RFeedback/imgs/dotlinefeed.svg"
import mobileimgfeedback from "../RFeedback/imgs/mobileimg-feedback.svg"
import mobconsfeed from "../RFeedback/imgs/mobconsfeed.svg"
import consfeedback from "../RFeedback/imgs/consolidated.svg"
import feedimg from "../RFeedback/imgs/feed.svg"
import Header from '../../Header/Navbar';
import Footer from '../../Footer/Footer/Footer';
import "./feedback2.css";



export default function Feedback() {

 
  return (

    <>
      <div>

        <div className="border">
          <Header />
        </div>

        <div className="feedback-rectangle">
          <div className="feedback-headding">
            <h1>Feedback</h1>
          </div>

          <div className="create-feedback">
            <div className="createfeedbackimg">
              <img src={feedimg} alt=""  className="deskfeedb"/>
              <img src={mobileimgfeedback} alt="" className="mobfeedb"/>
              
            </div>
          </div>
          <div className="smarter-feedback">
            <h1>Continous Feedback is a <span className="bold">Smarter</span> way of learning.</h1>
           
          </div>

          <div className="three-carrds">
            <div class="twocard">
              <div class="blok">
                <h1 className="cardheadings">Feedback Builder</h1>
                <img src={dotlinefimg} alt="p" className="dotlineimgg-feedback" />
                <p className="paragra">Creating a feedback has never been easier. With our UI, you will be able to push a feedback in less time it takes to finish a cup of coffee.</p>
              </div>

              <div class="blok">
                <h1 className="cardheadings">Collect Customer Feedback</h1>
                <img src={dotlinefimg} alt="p" className="dotlineimgg-feedback" />
                <p className="paragra">From Kognics, you can send feedback to not just your employees but to customer and business partners through a link, reducing the cost of a third party software</p>
              </div>
            </div>

            <div class="twocard">
              <div class="blok">
                <h1 className="cardheadings">Continous Feedback  Cycle</h1>
                <img src={dotlinefimg} alt="p" className="dotlineimgg-feedback" />
                <p className="paragra">
                Build better tranings with continous feedback to understand what your users likes and what would they like to study next.</p>
              </div>
            </div>
          </div>

          {/* <div className="time-savingg">
            <h1>Time <span className="boldd">Saving</span> benefits</h1>
         
          </div>

          <div className="consolidated-platformm-feedback">
            <div className="consolidatedd-feedback">
              <img src={consfeedback} alt="" className="deskconsfeed"/>
              <img src={mobconsfeed} alt="" className="mobconsfeed"/>
            </div>
          </div> */}
          
        </div>

        <div className="lastdiv">

        </div>

        <div>
          <Footer
            color="#1C5466"
            backgroundColor="#FFF0EF"
          />
        </div>


      </div>
    </>
  );
}