import React from 'react'
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import Management from "../../Images/NewHome/Management.svg"
import HumanResource from "../../Images/NewHome/Human Resource.svg"
import Leadership from "../../Images/NewHome/Leadership.svg"
import Arrow from "../../Images/NewHome/Arrow.svg"
import FourthArrow from "../../Images/NewHome/4thArrow.svg"

const ContentGrowing = () => {
    return (
        <div className='contentGrowing-Container'>
            <h2>Content? Always Growing</h2>
            <div className='Content-Card-Container'>
                <div className='Content-Card'>
                    <div className='content-cardInner'>
                        <div className='Link-More link-more'>
                            <a>Course Library</a>
                            <span className='Circle-div'></span>
                        </div>
                        <div className='card-item'>
                            <img src={Management} alt='management' width="70px" height="70px" />
                            <p>Management</p>
                        </div>
                        <div className='card-item'>
                            <img src={HumanResource} alt='Human Resource' width="70px" height="70px" />
                            <p>Human Resource</p>
                        </div>
                        <div className='card-item'>
                            <img src={Leadership} alt='Leadership' width="70px" height="70px" />
                            <p>Leadership</p>
                        </div>
                        <div className='Link-More'>
                            <Link to="/Content-Library">See more
                                <img src={Arrow} alt='Leadership' width="40px" height="40px" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ArrowImg'>
                <img src={FourthArrow} alt="arrow" width="12%" height="12%" />
            </div>
        </div>
    )
}

export default ContentGrowing