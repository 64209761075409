import React from 'react'
// import ToggleContainer from "./ToggleUI/ToggleUI"
import SignupToday from "./SingupToday"
import Header from '../../Header/Navbar'
import Footer from '../../Footer/Footer/Footer'
import "../../Style.css"
import "../../Style2.css"
import "./Home.scss"
import EductionSop from './EductionSop'
import ContentGrowing from './ContentGrowing'
import GetDemo from './GetDemo'
import PlatfromForAll from './PlatfromForAll'
import ButWhy from "./ButWhy"
import ImageSlider from './ImageSlider/ImageSlider'

const Home = () => {
    return (
        <div className='Home_Content'>
            <div className='header_finance'>
                <Header />
            </div>
            <div className='Finance_main'>
                <ImageSlider/>
                <SignupToday />
                <EductionSop />
                <ButWhy />
                <ContentGrowing />
                <PlatfromForAll />
                <GetDemo />
            </div>
            <Footer
                color="#1C5466"
                backgroundColor="#D6F5FF"
            />        
        </div>
    )
}

export default Home