import { useState } from 'react';
import { Container, Form, Modal } from 'react-bootstrap';
import "./popUp.scss";
import useResults from '../../../Hooks/useEmailSender';
const PopupExample = ({ title }) => {
    const [show, setShow] = useState(false);
    const { mailsender } = useResults()
    const [Displaystatus, setDisplay] = useState(true);
    const [wait, setWait] = useState(true)
    const [screenWidth, setScreenWidth] = useState(true)
    const handleClose = () => {
        setShow(false)
        setDisplay(true)
        setWait(true)
        setFormData({ Fname: '', Lname: '', email: '' })
    };
    const handleShow = () => {
        setShow(true)
        setDisplay(true)
        if (window.screen.width <= 430) {
            setScreenWidth(false)
        }
    };
    const [formData, setFormData] = useState({ Fname: '', email: '' });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };
    const backToHome = () => {
        setWait(true)
        setShow(false)
        setFormData({ Fname: '', Lname: '', email: '' })
    }
    const onSubmit = async (e) => {
        e.preventDefault()
        setWait(false)
        mailsender(formData, setDisplay)

    }


    return (
        <>
            <div className="login-btn_home" onClick={handleShow}>
                {title ? title : 'Get Started! No Credit Card'}
            </div>
            {
                screenWidth ?
                    <Modal show={show} onHide={handleClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className='modalStyle'
                    >
                        <Modal.Body>
                            <Container className='popUp-main'>
                                <Container style={Displaystatus ? { display: "block" } : { display: "none" }}>
                                    <div className='data-center'>
                                        <div className='data-cenetr-inner'>
                                            <div className='TopContent'>
                                                <div className='popupText'>
                                                    <h3>Get a <span>Demo !</span></h3>
                                                    <p className='mt-4'>
                                                        To see how Kognics works and how it’s going to increase your business’s  worth.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='PopupContent'>
                                                <Form onSubmit={onSubmit}>
                                                    <div className='container data_container'>
                                                        <div className='data_input'>
                                                            <label>Name</label>
                                                            <input placeholder='Name' name='Fname' value={formData.Fname} onChange={handleChange} required />
                                                        </div>
                                                        <div className='data_input'>
                                                            <label>Email</label>
                                                            <input placeholder='Email' name='email' value={formData.email} onChange={handleChange} required />
                                                        </div>
                                                    </div>
                                                    <div className="login-btn_popup">
                                                        <div className='Getdemo'>
                                                            <button className='loginBtn btn' type='submit'>{wait ? "Submit" : "Please Wait .."}</button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                                <div style={Displaystatus ? { display: "none" } : { display: "block" }} className="Second-Section">
                                    <div className='mt-4'>
                                        <div className='text-center' >
                                            <h3>
                                                <span>Thank you</span> for submitting your request
                                            </h3>
                                            <p>We will get in touch with you shortly</p>
                                        </div>
                                        <div className="login-btn_home login-btn_popup">
                                            <div className='Getdemo border-gradient-popUp'>
                                                <button className='loginBtn backToHome btn' onClick={backToHome}>Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </Modal.Body>
                    </Modal>
                    :
                    <Modal show={show} onHide={handleClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className='modalStyle_mobile'
                    >
                        <Modal.Body>
                            <Container className='popUp-main'>
                                <div style={Displaystatus ? { display: "block" } : { display: "none" }}>
                                    <div className='data-center'>
                                        <div className='data-cenetr-inner'>
                                            <div className='TopContent'>
                                                <div className='popupText'>
                                                    <h3>Get a <span>Demo !</span></h3>
                                                    <p className='mt-4'>
                                                        To see how Kognics works and how it’s going to increase your business’s  worth.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='PopupContent'>
                                                <Form onSubmit={onSubmit}>
                                                    <div className='container data_container'>
                                                        <div className='data_input'>
                                                            <input placeholder='First Name' name='Fname' value={formData.Fname} onChange={handleChange} required />
                                                        </div>
                                                        <div className='data_input'>
                                                            <input placeholder='Email' name='email' value={formData.email} onChange={handleChange} required />
                                                        </div>
                                                    </div>
                                                    <div className="login-popUpbtn">
                                                        <button className='loginBtn backToHome btn' type='submit'>{wait ? "Submit" : "Please Wait .."}</button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={Displaystatus ? { display: "none" } : { display: "block" }} className="Second-Section">
                                    <div className='mt-4'>
                                        <div className='text-center' >
                                            <h3>
                                                <span>Thank you</span> for submitting your request
                                            </h3>
                                            <p>We will get in touch with you shortly</p>
                                        </div>
                                        <div className="login-btn_home login-btn_popup">
                                            <div className='Getdemo border-gradient-popUp'>
                                                <button className='loginBtn backToHome btn' onClick={backToHome}>Back</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </Modal.Body>
                    </Modal>
            }


        </>
    );
}

export default PopupExample