import React from 'react';
import "./style.scss"
import Header from "../Header/Navbar"
import Footer from "../Footer/Footer/Footer"
import PostList from './BlogList';

const Blog = () => {

    return (

        <div className='Home_Content'>
            <div className='header_finance'>
                <Header />
            </div>
            <div className='blog-container mb-5'>
                <PostList />
            </div>
            <Footer
                color="#1C5466"
                backgroundColor="#D6F5FF"
            />
        </div>
    );
};

export default Blog;
