import React, { useEffect, useState } from 'react';
import {
    Typography, Container,
    Grid, Card, CardMedia,
    CardContent, Button, LinearProgress
} from '@material-ui/core';
import "./style.scss"

import { makeStyles } from '@material-ui/core/styles';
import { Link} from 'react-router-dom';
import axios from 'axios';
import { Pagination } from '@mui/material';
import useBlogPosts from '../Hooks/useResults';




const useStyles = makeStyles({
    textContainer: {
        maxHeight: '8em', // Set the maximum height of the container
        overflow: 'hidden', // Hide any overflow content
        textOverflow: 'ellipsis', // Display an ellipsis (...) for truncated text
        display: '-webkit-box', // Required for WebKit browsers like Safari and Chrome
        lineHeight: "23px",
        '-webkit-line-clamp': 5, // Set the number of lines to display
        '-webkit-box-orient': 'vertical', // Set the text flow direction to vertical
    },
});



const PostList = () => {
    const [posts, setPosts] = useState([]);
    const classes = useStyles();
    const { ImageId } = useBlogPosts();
    const [data, setData] = useState([]); // Store the image URLs in state
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(true);


    const fetchPosts = async (page) => {
        try {
            setIsLoading(true);
            const response = await axios.get(`https://blog.kognics.com/wp-json/wp/v2/posts?per_page=10&page=${page}`);
            setPosts(response.data);
            setTotalPages(response.headers['x-wp-totalpages']);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const imagePromises = posts?.map((post) => ImageId(post.featured_media)); // Map posts to ImageId promises
                const images = await Promise.all(imagePromises); // Wait for all promises to resolve
                const imageUrls = images?.map((image) => image); // Extract source URLs
                setData(imageUrls); // Set the image URLs in state
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchImages();
    }, [posts]);


    useEffect(() => {
        fetchPosts(currentPage);
    }, [currentPage]);

    return (
        <div style={{ height: isLoading ? 200 : '' }}>
            {isLoading ? (
                <div className="loader">
                    <LinearProgress />
                </div>
            ) : (
                <div className='Finance_main'>
                    <div className='blog-container mb-5'>
                        <Container maxWidth="md" style={{ paddingTop: "5rem" }}>
                            <Typography variant="h4" gutterBottom className='blogPosts-wapper'>Blog Posts</Typography>

                            <Grid container spacing={4} style={{ height: "100%" }}>
                                {
                                    posts?.map((post, index) => {
                                        const single_image = data[index % data.length]
                                        return (
                                            <Grid item xs={12} sm={6} md={4} key={post.id}>
                                                <Card style={{ maxWidth: 350, height: "20rem" }}>
                                                    {single_image && (
                                                        <CardMedia
                                                            style={{ height: 120 }}
                                                            image={single_image.source_url}
                                                            title={post.title.rendered}
                                                        />)}
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h6" component="div"
                                                            dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                                                        />

                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                                                            className={[classes.textContainer, 'expcert-box']}
                                                        />
                                                        <Link to={`/Blog/${post.slug}`}
                                                            state={{
                                                                id: post.featured_media
                                                            }}>
                                                            <Button variant="contained" className='read-more-btn'>Read More</Button>
                                                        </Link>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        );

                                    })
                                }
                            </Grid>
                        </Container>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={(event, page) => setCurrentPage(page)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PostList;
