import { useState } from 'react';
import { Container, Form, Modal } from 'react-bootstrap';
import "./popUp.css";

const Popup = ({ id, btnColor, title, arr }) => {
    const [show, setShow] = useState(false);
    const [Displaystatus, setDisplay] = useState(true);
    const [wait, setWait] = useState(true)
    const [sendId, setSendId] = useState()
    const [plan, setplan] = useState(true)
    // Last Name: ${formData.Lname} \n

    const handleClose = () => {
        setShow(false)
        setplan(true)
        setDisplay(true)
        setWait(true)
        setFormData({ Fname: '', email: '', plan: '' })
    };
    const handleShow = () => {
        setShow(true)
        setDisplay(true)
    };
    const [formData, setFormData] = useState({ Fname: '',email: '', plan: '' });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };
    const handleChanges = (event) => {
        setplan(false)
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }
    const backToHome = () => {
        window.location.reload()
        setWait(true)
        setShow(false)
        setFormData({ Fname: '', email: '', plan: '' })
    }
    const onSubmit = async (e) => {
        e.preventDefault()
        setWait(false)
        //const apiKey = '4482D0ED0CA10F9AF4679220336B97DD3D18AFCE6EF68F5771B4D721C20B87F55A19D99368C879BE1CD982AB97983F16';
        const api = "2789E8555DDDAEE29F4CEF00700FB8AD223E6E869E703A3E7214AED132A6CB80B1631AB07886218272EDFC4CC7A0D763";
        const senderEmail = "support@kognics.com"
        const ReceverEmail = "siddiq@kognics.com,dm@kognics.com,vishal@kognics.com";
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };
        const message = `Name: ${formData.Fname}\n
                         Email: ${formData.email} \n
                         Choosing Plan : ${id === sendId && plan ? title : formData.plan}`;

        try {
            //const res = await fetch(`https://api.elasticemail.com/v2/email/send?apikey=${apiKey}&subject= Choose Plan By User &from=kkumar95973@gmail.com&to=vishal@kognics.com&bodyHtml=${message}`, requestOptions)
            const res = await fetch(`https://api.elasticemail.com/v2/email/send?apikey=${api}&subject= Selected Plan By User &from=${senderEmail}&to=${ReceverEmail}&bodyHtml=${message}`, requestOptions)
            if (res.status === 200) {
                setDisplay(false)
            }
        } catch (e) {
            alert(e)
        }
    }

    return (
        <>

            <div className='pt-3' onClick={handleShow}>
                <button className='buybtn' id={id} style={{ backgroundColor: btnColor }} onClick={() => setSendId(id)}>
                    Buy
                </button>
            </div>
            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modalStyle'
            >
                <Modal.Body>
                    <Container className='popUp-main'>
                        <Container style={Displaystatus ? { display: "block" } : { display: "none" }}>
                            <div className='data-center'>
                                <div className='data-cenetr-inner'>
                                    <div className='TopContent'>
                                        <div className='popupText'>
                                            {/* <img src={getdemo} alt='getDemo' /> */}
                                            <h2>Get a <span>Demo!</span></h2>
                                            <p className='mt-3'>
                                                To see how Kognics works and how it’s going to increase your business worth.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='PopupContent'>
                                        <Form onSubmit={onSubmit} className="text-center">
                                            <div className='container data_container'>
                                                <div className='data_input'>
                                                    <label>Enter Name</label>
                                                    <input placeholder='Name' name='Fname' value={formData.Fname} onChange={handleChange} required />
                                                </div>
                                                {/* <div className='data_input'>
                                                    <input placeholder='Last Name' name='Lname' value={formData.Lname} onChange={handleChange} required />
                                                </div> */}
                                                <div className='data_input'>
                                                    <label>Enter Name</label>
                                                    <input placeholder='Email' name='email' value={formData.email} onChange={handleChange} required />
                                                </div>
                                                <div className='data_input'>
                                                    <label>Select the Category</label>
                                                    <Form.Group className='ms-1'>
                                                        <Form.Select id="disabledSelect" name='plan' value={formData.plan} onChange={handleChanges}>
                                                            <option className='defualt-colorFont'>
                                                                {id === sendId && plan ? title : null}
                                                            </option>
                                                            {
                                                                arr.map((card, index) => {
                                                                    return card.title !== title ? <option>{card.title}</option> : null
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <button className='buybtn-poPup'>{wait ? "Submit" : "Please Wait .."}</button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Container>
                        <div style={Displaystatus ? { display: "none" } : { display: "block" }} className="Second-Section">
                            <div className='mt-4'>
                                <div className='text-center' >
                                    <h3>
                                        <span>Thank you</span> for submitting your request
                                    </h3>
                                    <p>We will get in touch with you shortly</p>
                                </div>
                                <button className='buybtn-poPup' onClick={backToHome}>Back</button>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal >
        </>
    );
}

export default Popup