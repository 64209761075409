import React from 'react'
import Header from "../../Header/Navbar"
import Footer from "../../Footer/Footer/Footer"
import AboutIntro from './AboutIntro'
import "./About.scss"

import AboutUS1 from '../../Images/NewHome/aboutUs-1.svg'
import AboutUS2 from "../../Images/NewHome/2nd-section.svg"
import AboutUS3 from "../../Images/NewHome/3rd-section.svg"
import PopupExample from '../../Home/Home-UI/Pop-up/popUP'
import PlatfromForAll from '../../Home/Home-UI/PlatfromForAll'
import AboutRowContainer from './AboutRowContainer'
import ArrowFrist from "../../Images/NewHome/2ndArrow.svg"
import ArrowSecond from "../../Images/NewHome/1stArrow.svg"
import ArrowThrid from "../../Images/NewHome/3rdArrow.svg"
import ArrowFour from "../../Images/NewHome/4thArrow.svg"

import AboutContainer from './AboutContainer'
import finance from "../../Images/NewHome/Finance.svg"
import healthcare from "../../Images/NewHome/Healthcare.svg"
import retail from "../../Images/NewHome/Retail.svg"
import software from "../../Images/NewHome/Software.svg"
import manufacturing from "../../Images/NewHome/Manufacturing.svg"
//
import DataCardimg from '../../Images/NewHome/dataimg.svg'
import DataCardSecondimg from '../../Images/NewHome/Group 5697.svg'
import DataCardThridimg from '../../Images/NewHome/Group 5699.svg'
import DataCardFourthimg from '../../Images/NewHome/Group 5700.svg'
import ButWhy from '../../Home/Home-UI/ButWhy'



const About_us = () => {
    return (
        <div className='Home_Content'>
            <div className='header_finance'>
                <Header />
            </div>
            <div className='Finance_main'>
                <div className='about-container pt-5'>
                    <AboutIntro />
                </div>
                <div className='about-middle-section'>
                    <AboutRowContainer
                        title={<>BEST <span>LMS</span> SOLUTION PROVIDER IN <span>INDIA</span> !</>}
                        para={'Kognics focuses on delivering organiza   tions with next generation learning solutions to help organizations gain strategic market advantage.'}
                        img={AboutUS1}
                        PopUp={<PopupExample title="Get a Demo" />}
                        arrowImg={ArrowFrist}
                    />
                    <div className='pt-5 pb-5'>
                        <AboutRowContainer
                            title={<>It started with an <span>Idea</span> !</>}
                            para={'To create a learning technology that would make a real impact. That idea became a reality in September 2022 in Silicon Valley, United States. Since then Kognics has grown into a global company with remote offices in different countries.'}
                            img={AboutUS2}
                            style={{
                                backgroundColor: "white",
                                flexDirection: "row-reverse",
                                textAlign: "end",
                                PaddingRight: "5rem",
                                color: "rgba(28, 84, 102, 1)"
                            }}
                            arrowImg={ArrowSecond}
                        />
                    </div>
                    <div className='pt-5'>
                        <AboutRowContainer
                            title={<>Empower your employees and partners <span>Together</span>!</>}
                            para={
                                <>
                                    <p>
                                        Kognics has resolved all the training and learning barriers which stop them from growing and learning fast.
                                        We have made an enablement platform for all kinds of industries like healthcare, finance, manufacturing, business services, software, and retail.
                                    </p>
                                    <h3>Train your new hires quickly and effectively.</h3>
                                    <p>
                                        It’s time to move beyond traditional approaches to enterprise education.
                                        To successfully meet the challenges of today’s business world, organizations and the individuals
                                        who steer them should take advantage of online learning resources and opportunities.
                                    </p>
                                </>
                            }
                            img={AboutUS3}
                            style={{ backgroundColor: "white" }}
                            arrowImg={ArrowFrist}
                        />
                    </div>
                </div>
                <div className='about-bottom-section' style={{ paddingTop: "20%" }}>
                    <AboutContainer
                        title={<> About <span>Kognics</span> </>}
                        para={`Kognics is headquartered in Silicon Valley, California, United States,
                        and we offer enterprise-grade Enablement Software. We partner with several
                        companies globally across different verticals like software, healthcare, retail, manufacturing,
                        business services, finance, etc. Our next-generation training platform helps organizations make
                        learning their competitive advantage.`}
                    />
                </div>
                <div className='about-bottom-img'>
                    <div className='ArrowImg'>
                        <img src={ArrowThrid} alt="arrow" width="10%" height="10%" />
                    </div>
                </div>
                <div className='about-bottom-section' style={{ paddingTop: "5%" }}>
                    <AboutContainer
                        title={<> Our <span>Mission</span> </>}
                        para={`Kognics provides you with the best enablement software, which is the one-end solution to all the learning obstacles faced by industries.
                        So by thinking about all the worries we have decided to help enterprises by providing an engaging and impactful learning experience that improves business performance.`}
                        style={{ className: "our-mission" }}
                    />
                </div>
                <div className='about-bottom-img-right'>
                    <div className='ArrowImg'>
                        <img src={ArrowFour} alt="arrow" width="10%" height="10%" />
                    </div>
                </div>
                <div className='about-bottom-box'>
                    <PlatfromForAll
                        title={<> <span>Kognics</span> can be <span>everywhere</span> it is needed.</>}
                        id="aboutUS"
                        aboutdata={[
                            {
                                title: "Finance",
                                image: finance,
                                des: `Accelerate transformation with modern sales enablement. The Kognics Enablement Cloud is a place specially 
                                designed to upskill sales teams, deepen client relationships, and increase revenue.`
                            },
                            {
                                title: "Software",
                                image: software,
                                des: `Kognics unifies and connects with the systems that keep your business running, with creative learning management, delivery and super secure data unification.`
                            },
                            {
                                title: "Healthcare",
                                image: healthcare,
                                des: `Empower your reps to become subject-matter experts. Improve the customer experience with compliant, data driven content- tailored for each conversation.`
                            },
                            {
                                title: "Retail",
                                image: retail,
                                des: `Retailers need an enablement product for their retail business. It helps them to increase their customer experience. Leads to a proper business establishment.`
                            },
                            {
                                title: "Manufacturing",
                                image: manufacturing,
                                des: `Enables reps to sell complex products with confidence. Stand out from the competition by showcasing expertise with buyers across all your networks.`
                            }
                        ]}
                        paddingTop="inherit"
                    />
                </div>
                <div className='about-bottom-box-mobile'>
                    <ButWhy
                        id="aboutUS"
                        aboutdata={[
                            {
                                title: "Finance",
                                image: finance,
                                des: `Accelerate transformation with modern sales enablement. The Kognics Enablement Cloud is a place specially 
                                designed to upskill sales teams, deepen client relationships, and increase revenue.`
                            },
                            {
                                title: "Software",
                                image: software,
                                des: `Kognics unifies and connects with the systems that keep your business running, with creative learning management, delivery and super secure data unification.`
                            },
                            {
                                title: "Healthcare",
                                image: healthcare,
                                des: `Empower your reps to become subject-matter experts. Improve the customer experience with compliant, data driven content- tailored for each conversation.`
                            },
                            {
                                title: "Retail",
                                image: retail,
                                des: `Retailers need an enablement product for their retail business. It helps them to increase their customer experience. Leads to a proper business establishment.`
                            },
                            {
                                title: "Manufacturing",
                                image: manufacturing,
                                des: `Enables reps to sell complex products with confidence. Stand out from the competition by showcasing expertise with buyers across all your networks.`
                            }
                        ]}

                    />
                </div>
                <div className='about-bottom-img'>
                    <div className='ArrowImg'>
                        <img src={ArrowThrid} alt="arrow" width="10%" height="10%" />
                    </div>
                </div>
                <div className='about-bottom-section' style={{ paddingBottom: "10rem" }}>
                    <div className='heading-Conatiner'>
                        <div className='platfrom-heading'>
                            <h2>Why <span>choose</span> Kognics <span>?</span></h2>
                        </div>
                    </div>
                    <div className='wapper'>
                        <div className='box-container-read'>
                            <AboutContainer
                                id="aboutus"
                                style={{
                                    className: "boxcard",
                                }}
                                dataimg={DataCardimg}
                            />
                        </div>
                        <div className='box-container-read'>
                            <AboutContainer
                                id="aboutus"
                                style={{
                                    className: "boxcard",
                                }}
                                dataimg={DataCardSecondimg}
                            />
                        </div>
                        <div className='box-container-read'>
                            <AboutContainer
                                id="aboutus"
                                style={{
                                    className: "boxcard",
                                }}
                                dataimg={DataCardThridimg}
                            />
                        </div>
                        <div className='box-container-read'>
                            <AboutContainer
                                id="aboutus"
                                style={{
                                    className: "boxcard",
                                }}
                                dataimg={DataCardFourthimg}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Footer
                color="#1C5466"
                backgroundColor="#D6F5FF"
            />
        </div>
    )
}

export default About_us