import React from 'react'
const AboutContainer = ({ title, para, style, id, dataimg }) => {
    return (
        <>
            {
                id ?
                    <div className={`about-container-section ${style ? style.className : ''}`}>
                        <img src={dataimg} alt='img' width="100%" height="150%" />
                    </div>
                    :
                    <div className={`about-container-section ${style ? style.className : ''}`}>
                        <h2>
                            {title}
                        </h2>
                        <p>{para}</p>
                    </div>
            }
        </>
    )
}

export default AboutContainer