import React, { useState } from 'react'
import "./contactus.scss";
import Header from '../../Header/Navbar';
import Footer from '../../Footer/Footer/Footer';
import demoimg from "../RContactUsDemo/imgs/demoimg.svg"
import "./contactus2.css";
import useResults from '../../Hooks/useEmailSender';
import { Link } from 'react-router-dom';

const ContactUs = () => {

  const [show, setShow] = useState(false);
  const { mailsender } = useResults()
  const [Displaystatus, setDisplay] = useState(true);
  const [wait, setWait] = useState(true)
  const [screenWidth, setScreenWidth] = useState(true)

  
  const [formData, setFormData] = useState({ Fname: '', Lname: '', email: '' });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };
  const backToHome = () => {
    setWait(true)
    setShow(false)
    setFormData({ Fname: '', Lname: '', email: '' })
  }
  const onSubmit = async (e) => {
    e.preventDefault()
    setWait(false)
    mailsender(formData, setDisplay)

  }

  return (
    <>
      <div>

        <div className="border">
          <Header />
        </div>

        <div className="demo-rectangle">

          <div class="demo-image">
            <img src={demoimg} />
          </div>

          <div class="demo-form">
            <div class="form-wrapper">
              <h2>Get a <span className="bold-contact">Demo!</span></h2>
              <p>To see how Kognics works and how it’s going to increase your business’s  worth.</p>

              <form className="text-container" onSubmit={onSubmit} style={Displaystatus ? { display: "block" } : { display: "none" }}>
                <div className="input-container">
                  <div className="input">
                    <input type="text" placeholder="First Name" name='Fname' value={formData.Fname} onChange={handleChange} required />
                    <input type="text" placeholder="Last Name" name='Lname' value={formData.Lname} onChange={handleChange} required />
                    <input type="text" placeholder="Email" name='email' value={formData.email} onChange={handleChange} required />
                  </div>
                </div>
                  <button className="btn">{wait ? "Submit" : "Please Wait .."}</button>
              </form>
              <div style={Displaystatus ? { display: "none" } : { display: "block", position: "relative" }} className="Second-Section">
                <div className='mt-4'>
                  <div className='text-center' >
                    <h3>Thank you for submitting your request</h3>
                    <p>We will get in touch with you shortly</p>
                  </div>
                  <Link to="/">
                    <button className='button' onClick={backToHome}>Back</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="last-contact">

        </div>

        <div>
          <Footer
            color="#1C5466"
            backgroundColor="linear-gradient(180deg, #DCFFF9 0%, #DCFFF9 100%)"
          />
        </div>

      </div>
    </>
  )
}

export default ContactUs