import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Arrow from "../../Images/NewHome/Arrow.svg"
import DemoImg from "../../Images/NewHome/DemoImg.svg"
import useResults from "../../Hooks/useEmailSender"

const GetDemo = () => {

    const { mailsender } = useResults()
    const [formData, setFormData] = useState({ email: '' });
    const [Displaystatus, setDisplay] = useState(true);

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };
    const onSubmit = async (e) => {
        e.preventDefault()
        mailsender(formData, setDisplay)
        setFormData({ email: '' })
    }
    useEffect(() => {
        if (!Displaystatus) {
            alert("Email Send successfully")
        }
    }, [onSubmit])

    return (
        <div className='GetDemo-Container'>
            <Container>
                <Row>
                    <Col xs={12} md={6}>
                        <div className='leftSide'>
                            <h2>Get a  <span>Demo!</span></h2>
                            <p>To see how Kognics works and how it’s going to increase your business’s  worth.</p>
                            <form onSubmit={onSubmit}>
                                <div className='leftSide-getDemo'>
                                    <input placeholder='Enter your email' name='email' value={formData.email} onChange={handleChange} required />
                                    <button type='submit'>
                                        <img src={Arrow} width="30px" height="30px" />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className='rightSide'>
                            <img src={DemoImg} alt='course' width="90%" height="90%" />
                        </div>
                        <div className='rightSide-mobile'>
                            <p>To see how Kognics works and how it’s going to increase your business’s  worth.</p>
                            <form onSubmit={onSubmit}>
                                <div className='leftSide-getDemo'>
                                    <input placeholder='Enter your email' name='email' value={formData.email} onChange={handleChange} required />
                                    <button type='submit'>
                                        <img src={Arrow} width="30px" height="30px" />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default GetDemo;
