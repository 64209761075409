import React, { useState, useRef } from 'react'
import Header from "../Header/Navbar"
import Footer from "../Footer/Footer/Footer"
import "./Pricing.css"
import PricingCard from './PricingCard';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Mousewheel } from "swiper";
import PaperPlaneAnimation from './PaperPlanAnimation/PaperPlaneAnimation';

const Pricing = () => {

    const [check, setCheck] = useState(false)
    const ClickHandel = (e) => {
        console.log(e.target.id)
        if (e.target.id === "Quarterly") {
            setCheck(false)
        } else if (e.target.id === "Yearly") {
            setCheck(true)
        }
    }

    const PricingCards = [
        {
            id: "Blueprint",
            title: "Blueprint",
            NoUser: "No. Of Users: 1-10 Users",
            Frequency: "Yearly",
            // img: `${blueprint}`,
            priceYear: "11611.2",
            btnColor: "#1F5262",
            bgColor: "#D6F5FF",
            color: "#1F5262"
        },
        {
            id: "Prototype",
            title: "Prototype",
            NoUser: "No. Of Users: 10-50 Users",
            Frequency: `${check ? "Yearly" : "Quarterly"}`,
            PricePerUser: `${check ? "180/Month" : "210"}`,
            // img: `${prototype}`,
            btnColor: "#4E490F",
            bgColor: "#FFFDE3",
            color: "#4E490F"
        },
        {
            id: "Model",
            title: "Model",
            NoUser: "No. Of Users: 50-100 Users",
            Frequency: `${check ? "Yearly" : "Quarterly"}`,
            PricePerUser: `${check ? "175/Month" : "190"}`,
            price: "Price(INR): Based on Users",
            // img: `${model}`,
            btnColor: "#373140",
            bgColor: "#E8DFF5",
            color: "#373140"
        },
        {
            id: "Flying",
            title: "Flying",
            NoUser: "No. Of Users: 100+ Users",
            Frequency: `${check ? "Yearly" : "Quarterly"}`,
            PricePerUser: `${check ? "150/Month" : "170"}`,
            Price: "Price(INR): Based on Users",
            // img: `${flying}`,
            btnColor: "#55120F",
            bgColor: "#FFF0EF",
            color: "#55120F"
        }
    ]

    return (
        <div className='Pricing-Page'>
            <div className='header_finance'>
                <Header />
            </div>
            <div className='Main-PricingPage-Conatiner'>
                <div className='PricingPage'>
                    <div className='top-section'>
                        <div className='top-inner-section'>
                            <div className='pricingText-section'>
                                <h2>PRICING</h2>
                                <p>Capability and Performance, at a discounted price</p>
                            </div>
                        </div>
                    </div>
                    <div className='Pricing-container'>
                        <div className='paperAnimation'>
                            <PaperPlaneAnimation />
                        </div>
                        <div className='switch-btn mt-5'>
                            <div className='switch-btn-inner'>
                                <div className='switch-btn-main'>
                                    <p id='Quarterly' className={check ? null : "commonClass-switchbtn"} style={check ? { fontWeight: "inherit" } : null} onClick={(e) => ClickHandel(e)}>Quarterly</p>
                                    {/* <p>
                                    <Form>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            ref={inputRef}
                                            onClick={dataHandel}
                                            data-on="Yes"
                                            data-off="No"
                                        />
                                    </Form>
                                </p>*/}
                                    <p id='Yearly' className={check ? "commonClass-switchbtn" : null} style={check ? null : { fontWeight: "inherit" }} onClick={(e) => ClickHandel(e)}>Yearly</p>
                                </div>
                            </div>
                        </div>
                        <div className='PricingCards'>
                            <div className='PricingCard_inner'>
                                {
                                    check ?
                                        PricingCards.map((card, index) => {
                                            return <PricingCard
                                                key={index}
                                                title={card.title}
                                                NoUser={card.NoUser}
                                                Frequency={card.Frequency}
                                                PricePerUser={card.PricePerUser}
                                                img={card.img}
                                                price={card.price}
                                                priceYear={card.priceYear}
                                                btnColor={card.btnColor}
                                                bgColor={card.bgColor}
                                                color={card.color}
                                                id={card.id}
                                                arr={PricingCards}
                                            />
                                        })
                                        : PricingCards.map((card, index) => {
                                            return <PricingCard
                                                key={index}
                                                title={card.title}
                                                NoUser={card.NoUser}
                                                Frequency={card.Frequency}
                                                PricePerUser={card.PricePerUser}
                                                img={card.img}
                                                price={card.price}
                                                priceYear={card.priceYear}
                                                btnColor={card.btnColor}
                                                id={card.id}
                                                arr={PricingCards}
                                                bgColor={card.bgColor}
                                                color={card.color}
                                            />
                                        })
                                }
                            </div>

                            <div className='PricingCard_inner-mobile'>

                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    pagination={false}
                                    autoplay={{
                                        delay: 2500,
                                    }}
                                    mousewheel={true}
                                    modules={[Mousewheel]}
                                    className="Pricing_Card"
                                >
                                    {
                                        check ?
                                            PricingCards.map((card, index) => {
                                                return (
                                                    <SwiperSlide key={index}>
                                                        <PricingCard
                                                            key={index}
                                                            title={card.title}
                                                            NoUser={card.NoUser}
                                                            Frequency={card.Frequency}
                                                            PricePerUser={card.PricePerUser}
                                                            img={card.img}
                                                            price={card.price}
                                                            priceYear={card.priceYear}
                                                            btnColor={card.btnColor}
                                                            id={card.id}
                                                            arr={PricingCards}
                                                        />
                                                    </SwiperSlide>
                                                )
                                            }) : PricingCards.map((card, index) => {
                                                return (
                                                    <SwiperSlide key={index}>
                                                        <PricingCard
                                                            key={index}
                                                            title={card.title}
                                                            NoUser={card.NoUser}
                                                            Frequency={card.Frequency}
                                                            PricePerUser={card.PricePerUser}
                                                            img={card.img}
                                                            price={card.price}
                                                            priceYear={card.priceYear}
                                                            btnColor={card.btnColor}
                                                            id={card.id}
                                                            arr={PricingCards}
                                                        />
                                                    </SwiperSlide>
                                                )
                                            })
                                    }
                                </Swiper>
                            </div>
                        </div>
                        <div className='backgrounImage'>
                            <a href='https://app.kognics.com/login' target="_blank">
                                <button className='getFreebtn' id='Get-Free-Trial'>Get 1 month free trial</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer
                color="#1C5466"
                backgroundColor="#DCFFF9"
            />
        </div>
    )
}

export default Pricing
