import React, { useEffect } from 'react'
import Modal from './Modal';
import Home from './Components/Home/Home-UI/Home'
import ScrollToTop from './Components/ScrollToTop'
import About from './Components/About us/AboutUI/About_us';
import TermsCondition from './Components/terms';
import CourseLibrary from './Components/CourseLibrary/CourseLibrary';
import Feedback from "./Components/ExplorePages/RFeedback/Feedback";
import ProductHub from "./Components/ExplorePages/RProducthub/Producthub";
import Contact from "./Components/ExplorePages/RContactUsDemo/ContactUs";
import Quizess from "./Components/ExplorePages/RQuizzes/Quizzes";
import LearningPath from "./Components/ExplorePages/RLearningPath/LearningPath";
import Course from "./Components/ExplorePages/RCourse/Course";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Pricing from './Components/Pricing/Pricing';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import Blog from './Components/Blog/Blog';
import BlogPost from './Components/Blog/BlogPost';


function App() {

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = 'https://kognics.ai';
    }, 2000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Modal openL={true}/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Feedback' element={<Feedback />} />
          <Route path='/Quizess' element={<Quizess />} />
          <Route path='/Product-Hub' element={<ProductHub />} />
          <Route path='/Quizzess' element={<Quizess />} />
          <Route path='/Learning-Path' element={<LearningPath />} />
          <Route path='/Courses' element={<Course />} />
          <Route path='/Content-Library' element={<CourseLibrary />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/Terms-Condition' element={<TermsCondition />} />
          <Route path='/Privacy-Policy' element={<PrivacyPolicy />} />
          <Route path='/Blog' element={<Blog />} />
          <Route path="/Blog/:postId" element={<BlogPost />} />
        </Routes>

      </Router>
    </>
  );
}

export default App