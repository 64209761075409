import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        height: 200,
        backgroundColor: "#2a7086",
        padding: theme.spacing(2, 4, 3),
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
}));

const SimpleModal = ({ openL }) => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const body = (
        <div style={modalStyle} className={classes.paper}>
            <div>
                <h2 id="simple-modal-title" style={{ fontSize: "20px", textAlign: "center" }}>Attention!!</h2>
                <p id="simple-modal-description" style={{ textAlign: "center", fontSize: "18px" }}>
                    Kognics have a new address now and redirect it to
                    <a href='https://kognics.ai/' style={{fontSize:"22px" ,color: "yellow", textUnderlineOffset:"6px" }} > kognics.ai</a>
                </p>
            </div>
            <SimpleModal />
        </div>
    );

    return (
        <div>
            <Modal
                open={openL}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}
export default SimpleModal