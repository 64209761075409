import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import CourseHome from "../../Images/NewHome/CourseHome.svg";
import ArrowFrist from "../../Images/NewHome/1stArrow.svg";
import PopupExample from './Pop-up/popUP';

const SignupToday = () => {
    return (
        <div className='singUp-Container'>
            <Container>
                <Row>
                    <Col xs={12} md={6}>
                        <div className='leftSide'>
                            <h2>Make your employee training <span>effortless</span></h2>
                            <p>Kognics helps you to scale your team by making Onboarding, Training and Documentation a cake walk. Everything everywhere all in one location</p>
                            <h3>Sign up <span>Today!</span></h3>
                            <PopupExample />
                            <img src={CourseHome} alt='course' width="100%" height="100%" className='courserHome-mobile' />
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className='rightSide'>
                            <img src={CourseHome} alt='course' width="100%" height="100%" />
                        </div>
                        <div className='rightSide-mobile'>
                            <p>Kognics helps you to scale your team by making Onboarding, Training and Documentation a cake walk. Everything everywhere all in one location</p>
                            <h3>Sign up <span>Today!</span></h3>
                            <PopupExample />
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className='ArrowImg'>
                <img src={ArrowFrist} alt="arrow" width="15%" height="15%" />
            </div>
        </div>
    );
};

export default SignupToday;
