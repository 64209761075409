import React from 'react'
import PlatFrom from "../../Images/NewHome/Centertick.svg"
import bookhouse from "../../Images/NewHome/bookhouse.svg"
import mobileLearning from "../../Images/NewHome/mobileLearning.svg"
import BulidCourse from "../../Images/NewHome/Course.svg"
import Gamification from "../../Images/NewHome/Gamification.svg"
import Integrations from "../../Images/NewHome/Integrations.svg"
import Insights from "../../Images/NewHome/Insights.svg"
import Quizzes from "../../Images/NewHome/Quizees.svg"
import lineImg from "../../Images/NewHome/line2.svg"


import ThridImg from "../../Images/NewHome/3rdArrow.svg"
import CardUI from './CardUI/Card'

const ButWhy = ({ id, aboutdata }) => {
    return (
        <div className='ButWhy-container'>
            <div className='heading-Conatiner'>
                <div className='platfrom-heading'>
                    <h2>But Why Us?</h2>
                </div>
            </div>
            <div className='platfrom-containerInner'>
                <div className='platfrom-img-container'>
                    <img src={PlatFrom} alt='platfromImg' width="100%" height="100%" />
                    <div className='Platfroms'>
                        <div className='Platfroms-topItems'>
                            <div className='platfromItem bookHouse'>
                                <CardUI
                                    title="Book House"
                                    img={bookhouse}
                                    des={`With our growing content
                                    partnerships, gain access to
                                    resources that help you in
                                    building better courses.`}
                                    sections={["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                                />
                            </div>
                            <div className='platfromItem mobile'>
                                <CardUI
                                    title="Mobile"
                                    img={mobileLearning}
                                    des={`Offer your learners a seamless
                                    training experience with the
                                    Kognics Mobile App.`}
                                    sections={["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                                />
                            </div>
                            <div className='platfromItem Quizzes'>
                                <CardUI
                                    title="Quizzes"
                                    img={Quizzes}
                                    des={`Easily build quizzes to check the progress of your team members.`}
                                    sections={["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                                />
                            </div>
                        </div>
                        <div className='Platfroms-MiddelItems'>
                            <div className='platfromItem BuildCourses'>
                                <CardUI
                                    title="Build Courses"
                                    img={BulidCourse}
                                    des={`Kognics helps you to create collective and engaging  
                                    training courses by using built-in course authoring tools. `}
                                    sections={["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                                />
                            </div>
                            <div className='platfromItem Gamification'>
                                <CardUI
                                    title="Gamification"
                                    img={Gamification}
                                    des={`Boost learner engagement
                                    and course completion using
                                    points, batches, certificates
                                    and more.`}
                                    sections={["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                                />
                            </div>
                        </div>
                        <div className='Platfroms-bottomItems'>
                            <div className='platfromItem Integrations'>
                                <CardUI
                                    title="Integrations"
                                    img={Integrations}
                                    des={`Kognics will help sales representatives spend less time
                                    digging through multiple platforms & more time doing what matters, 
                                    i.e., selling & improving their skills.`}
                                    sections={["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                                />
                            </div>
                            <div className='platfromItem Insights'>
                                <CardUI
                                    title="Insights"
                                    img={Insights}
                                    des={`Kognics offers detailed analytics for key sales metrics where you can thoroughly understand effectiveness of all the 
                                    sales activities & make data-driven decisions to improves sales initiatives or develop a specific skill in individual reps.`}
                                    sections={["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='platfrom-containerInner-mobile'>
                <div className='platfrom-img-container'>
                    <img src={lineImg} alt='platfromImg' width="100%" height="100%" />
                    <div className='Platfroms'>
                        <div className='platfromItem bookHouse'>
                            <CardUI
                                title={id ? aboutdata[0].title : "Book House"}
                                img={id ? aboutdata[0].image : bookhouse}
                                des={id ? aboutdata[0].des : `With our growing content
                                partnerships, gain access to
                                resources that help you in
                                building better courses.`}
                                sections={
                                    id ? ["Finance", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"] :
                                        ["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                            />
                        </div>
                        <div className='platfromItem'>
                            <CardUI
                                title={id ? aboutdata[1].title : "Mobile"}
                                img={id ? aboutdata[1].image : mobileLearning}
                                des={id ? aboutdata[1].des :
                                    `Offer your learners a seamless
                                training experience with the
                                Kognics Mobile App.`}
                                sections={id ? ["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"] : ["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                            />
                        </div>
                        <div className='platfromItem'>
                            <CardUI
                                title={id ? aboutdata[2].title : "Quizzes"}
                                img={id ? aboutdata[2].image : Quizzes}
                                des={id ? aboutdata[2].des : `Easily build quizzes to check the progress of your team members.`}
                                sections={
                                    id ? ["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"] :
                                        ["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                            />
                        </div>
                        <div className='platfromItem'>
                            <CardUI
                                title={id ? aboutdata[3].title : "Build Courses"}
                                img={id ? aboutdata[3].image : BulidCourse}
                                des={id ? aboutdata[3].des : `Kognics helps you to create collective and engaging  
                                training courses by using built-in course authoring tools. `}
                                sections={id ? ["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"] : ["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                            />
                        </div>
                        <div className='platfromItem Gamification'>
                            <CardUI
                                title={id ? aboutdata[4].title : "Gamification"}
                                img={id ? aboutdata[4].image : Gamification}
                                des={id ? aboutdata[4].des : `Boost learner engagement
                                and course completion using
                                points, batches, certificates
                                and more.`}
                                sections={id ? ["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"] : ["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                            />
                        </div>
                        <div className='platfromItem Integrations'>
                            <CardUI
                                title={"Integrations"}
                                img={Integrations}
                                des={`Kognics will help sales representatives spend less time
                                    digging through multiple platforms & more time doing what matters, 
                                    i.e., selling & improving their skills.`}
                                sections={["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                            />
                        </div>
                        <div className='platfromItem Insights'>
                            <CardUI
                                title={"Insights"}
                                img={Insights}
                                des={`Kognics offers detailed analytics for key sales metrics where you can thoroughly understand effectiveness of all the 
                                sales activities & make data-driven decisions to improves sales initiatives or develop a specific skill in individual reps.`}
                                sections={["Book House", "Mobile", "Quizzes", "Build Courses", "Gamification", "Integrations", "Insights"]}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='ArrowImg'>
                <img src={ThridImg} alt="arrow" width="12%" height="12%" />
            </div>
        </div>
    )
}

export default ButWhy