import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import dropDown from '../Images/drop_down.svg';
import Dropdown from "../DropDown/dropdown";
import logo from "../Images/logo.svg";
import FoxSitting from "../Images/NewHome/FOX sitting.svg"
import "./style.scss"
// import dropdown from "../DropDown/dropdown";

const NavBar = () => {
    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        } else {
            updateNavbar(false);
        }
    }

    window.addEventListener("scroll", scrollHandler);


    const [headerClass, setHeaderClass] = useState('header')
    const [solutionHeight, setSolutionHeight] = useState('0');
    const [resourceHeight, setResourceHeight] = useState('0');
    const [solutionDropdown, setSolutionDropdown] = useState('none');
    const [resourceDropdown, setResourceDropdown] = useState('none')
    const [showing, setShowing] = useState(false);
    const [showIndu, setshowIndu] = useState(false);
    const [showUseCase, setshowUseCase] = useState(false);
    const [showResources, setshowResources] = useState(false);



    const onScroll = () => {
        if (window.scrollY > 0) {
            if (window.innerWidth > 426) {
                setHeaderClass('header-color')
            } else {
                setHeaderClass('header-mobile-color')
            }
        } else {
            setHeaderClass('header')
        }
    }

    document.addEventListener('scroll', onScroll)


    //Fetch URL
    var url = window.location.pathname;
    var page_ID = url.substring(url.lastIndexOf('/') + 1);
    const [page, setpage] = useState(page_ID)

    //Resource DropDown
    const ResourcesClick = () => {
        document.querySelector('.resource_dropdown').classList.toggle('dropdown_rotate')
        document.querySelector('.solution_dropDown').classList.remove('dropdown_rotate')
        if (resourceHeight === '0') {
            setResourceDropdown('block')
            setSolutionDropdown('none')
            setResourceHeight('90vw')
            setSolutionHeight('0')
            setHeaderClass('header-color')
        } else {
            setResourceHeight('0')
            setSolutionHeight('0')
            setSolutionDropdown('none')
            setResourceDropdown('none')
            setHeaderClass('header')
        }
    }
    // Solution DropDown
    const handleOpen = () => {
        document.querySelector('.solution_dropDown').classList.toggle('dropdown_rotate')
        if (solutionHeight === '0') {
            setSolutionDropdown('block')
            setResourceDropdown('none')
            setSolutionHeight('90vw')
            setResourceHeight('0')
            setHeaderClass('header-color')
        } else {
            setSolutionDropdown('none')
            setResourceDropdown('none')
            setSolutionHeight('0')
            setResourceHeight('0')
            setHeaderClass('header')
        }
    };
    const closeDropdown = () => {
        document.querySelector('.solution_dropDown').classList.remove('dropdown_rotate')
        setHeaderClass('header')
        // setheight('0')
        setResourceHeight('0')
        setSolutionHeight('0')
        setResourceDropdown('none')
        setSolutionDropdown('none')
    }
    // const closeResourseDropdown = () => {
    //   document.querySelector('.resource_dropdown').classList.remove('dropdown_rotate')
    // }

    const mobliedropDown = () => {
        setShowing(!showing)
    }

    const mobiledropDown_industries = () => {
        setshowIndu(!showIndu)
    }
    const mobiledropDown_useCase = () => {
        setshowUseCase(!showUseCase)
    }
    const mobiledropDown_Resource = () => {
        setshowResources(!showResources)
    }


    return (
        <>
            <Navbar
                expanded={expand}
                fixed="top"
                expand="md"
                className={`${headerClass}    ${navColour ? "sticky" : "navbar "}`}
            >
                <Navbar.Brand href="/" className="d-flex">
                    <img src={logo} className="img-fluid" alt="brand" />
                    {/* <b className="purple">
                        WebVishal.
                    </b> */}
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    onClick={() => {
                        updateExpanded(expand ? false : "expanded");
                    }}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto" defaultActiveKey="#home">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                                The How
                            </Nav.Link>
                            <img className='dropDown solution_dropDown' src={dropDown} alt='dropDown' onClick={handleOpen} />
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/about"
                                onClick={() => updateExpanded(false)}
                            >
                                About
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                to="/Pricing"
                                onClick={() => updateExpanded(false)}
                            >
                                Pricing
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                as={Link}
                                onClick={() => updateExpanded(false)}
                                className="loginbtn"
                                to="https://app.kognics.com/login"
                                target="_blank"
                            >
                                Login
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="fork-btn ps-4">
                            <Button
                                href="https://signup.kognics.com/"
                                target="_blank"
                                className="fork-btn-inner"
                            >
                                Sign up Today
                            </Button>
                        </Nav.Item>
                        <Nav.Item className="foxsitting">
                            <img src={FoxSitting} alt="foxsitting" width="80%" height="80%" />
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className={`DropDown`} style={{ height: `${solutionHeight}`, display: `${solutionDropdown}` }} onClick={closeDropdown}>
                <Dropdown
                    page={page}
                />
            </div>
        </>
    );
}

export default NavBar;