import React from 'react'
import { Container } from 'react-bootstrap'

const AboutIntro = () => {
    return (
        <Container className='about-intro'>
            <p>We took the responsibility of getting people to succeed!</p>
        </Container>
    )
}

export default AboutIntro