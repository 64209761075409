import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import SopEduction from "../../Images/NewHome/Sop-Eduction.svg"
import ArrowSecond from "../../Images/NewHome/2ndArrow.svg"
import PopupExample from './Pop-up/popUP';
const EductionSop = () => {
    return (
        <div className='EductionSop-Container'>
            <Container>
                <Row>
                    <Col xs={12} md={6}>
                        <div className='rightSide'>
                            <img src={SopEduction} alt='course' width="100%" height="100%" />
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className='leftSide'>
                            <h2>Make your education and SOP clutter-free <span>Today!</span> </h2>
                            <div>
                                <img src={SopEduction} alt='course' width="100%" height="100%" />
                            </div>
                            <p>Kognics helps you in digitizing your SOPs and Courses, making it highly searchable and available to the team members all the time so they can learn and grow at the pace they like </p>
                        </div>
                        <PopupExample />
                    </Col>
                </Row>
            </Container>
            <div className='ArrowImg'>
                <img src={ArrowSecond} alt="arrow" width="15%" height="15%" />
            </div>
        </div>
    );
};

export default EductionSop;
