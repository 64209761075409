import React from 'react'
import Header from '../Header/Navbar'
import Footer from '../Footer/Footer/Footer'
import "./CourseLibrary.css"
import CardUI from './CardUI'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";
import PerManagement from "../Images/NewHome/Content-Library/human-resources.svg"
import CSC from "../Images/NewHome/Content-Library/CreatingServiceSculture.svg"


import PresonalDevelopment from "../Images/NewHome/Content-Library/Powerful Presentation Skills.svg"
import InternalPresonal from "../Images/NewHome/Content-Library/Interpersonal Skills.svg"
import BusinessWriting from "../Images/NewHome/Content-Library/Business Writing.svg"


const CourseLibary = () => {



    const ContentCard = [

        {
            Title: "Human Resources",
            Heading: "Performance Management",
            describe: "Learn how performance management drives team success policy development and decision-making trends.",
            img: PerManagement,
            Style: {
                backgroundColor: "#A4C1E1",
                hoverColor: "linear-gradient(180deg, rgba(42, 112, 134, 0) 0%, #123862 100%)"
            }
        },
        {
            Title: "Human Resources",
            Heading: "Creating a service culture",
            describe: "This HR course equips you with essential skills like identifying needs, building rapport, and handling challenges for delivering exceptional customer service.",
            img: CSC,
            Style: {
                backgroundColor: "#F6A758",
                hoverColor: "linear-gradient(180deg, rgba(115, 63, 12, 0) 0%, #733F0C 100%)"
            }

        },
        {
            Title: "Human Resources",
            Heading: "Attracting, retaining and engaging workplace",
            describe: "Discover techniques to build a high-performing team, foster employee satisfaction, and achieve business success.",
            img: "https://drive.google.com/uc?export=view&id=1723Ya7yEAXeuX6c6yVCuuJOz3jrfVHBz",
            Style: {
                backgroundColor: "#B9E1A8",
                hoverColor: "linear-gradient(180deg, rgba(57, 99, 39, 0) 0%, #396327 100%)"
            }

        },
        {
            Title: "Human Resources",
            Heading: "Delegation",
            describe: "Learn principles, stages, and overcome barriers to become a skilled delegator with this course.",
            img: "https://drive.google.com/uc?export=view&id=1rFvwg8NhbQ5v8IUa4HjwFDuHTps7yajL",
            Style: {
                backgroundColor: "#A0C7D4",
                hoverColor: "linear-gradient(180deg, rgba(45, 103, 122, 0) 0%, #2D677A 100%)"
            }

        },
        {
            Title: "Human Resources",
            Heading: "Coaching Skills bootcamp",
            describe: "Upgrade your leadership style with coaching skills that create empowered, self-sufficient teams with Intensive boot camp format.",
            img: "https://drive.google.com/uc?export=view&id=16NP55u7LE3TRoSeJOPghjNGR097LdHtQ",
            Style: {
                backgroundColor: "#EBDC9B",
                hoverColor: "linear-gradient(180deg, rgba(146, 127, 45, 0) 0%, #927F2D 100%)"
            }

        },
        {
            Title: "Human Resources",
            Heading: "Respect in Workplace",
            describe: "Create a safe, inclusive, and productive workplace by fostering respect, communication, and diversity.",
            img: "https://drive.google.com/uc?export=view&id=1xoJYjwv7LAvCcWWyCPtXQ9vSaezuhrbM",
            Style: {
                backgroundColor: "#96D4C8",
                hoverColor: "linear-gradient(180deg, rgba(22, 114, 96, 0) 0%, #167260 100%)",
                Imgmargin: "5%"
            }

        },
        {
            Title: "Human Resources",
            Heading: "Effective Communication Skills for the Workplace",
            describe: "Discover the art of effective communication to improve collaboration and strengthen relationships in your workplace.",
            img: "https://drive.google.com/uc?export=view&id=1-TotJOc1sJ55oA9DMRCluLBLuBG_QNdu",

            Style: {
                backgroundColor: "#E3DCFF",
                hoverColor: "linear-gradient(180deg, rgba(65, 53, 113, 0) 0%, #413571 100%)"
            }

        },
        {
            Title: "Human Resources",
            Heading: "Effective Recognition and Reward Programs",
            describe: "Examine how to craft recognition and reward programs that motivate and retain employees for a greater success benefit.",
            img: "https://drive.google.com/uc?export=view&id=1kj5KDNH_M4j_aFs07Rl7sROmFD6BrpMr",
            Style: {
                backgroundColor: "#B4D3D8",
                hoverColor: "linear-gradient(180deg, rgba(42, 102, 112, 0) 0%, #2A6670 100%)"
            }

        },
        {
            Title: "Human Resources",
            Heading: "Performance Management",
            describe: "Learn how performance management drives team success policy development and decision-making trends.",
            img: "https://drive.google.com/uc?export=view&id=1KUqL-lNAEkj-QgRDSm23R6p1RitGIhEW",
            Style: {
                backgroundColor: "#A4C1E1",
                hoverColor: "linear-gradient(180deg, rgba(42, 112, 134, 0) 0%, #123862 100%)"
            }
        },


        //Personal/Professional Development
        {
            Title: "Personal and Professional development",
            Heading: "Powerful Presentation Skills",
            describe: "This course is tailored to help individuals enhance their communication abilities, conquer stage fright, and create memorable presentations that leave lasting impressions.",
            img: PresonalDevelopment,
            Style: {
                backgroundColor: "#A0EBB2",
                hoverColor: "linear-gradient(180deg, rgba(45, 140, 68, 0) 0%, #2D8C44 100%)",
                Imgmargin: "10%"
            }
        },
        {
            Title: "Personal and Professional development",
            Heading: "Interpersonal Skills",
            describe: "This course focuses on developing essential interpersonal skills that are crucial for effective communication, teamwork and relationships-building.",
            img: InternalPresonal,
            Style: {
                backgroundColor: "#F5C0A2",
                hoverColor: "linear-gradient(180deg, rgba(142, 90, 61, 0) 0%, #8E5A3D 100%)",
                Imgmargin: "10%"
            }
        },
        {
            Title: "Personal and Professional development",
            Heading: "Business Writing and Grammar Skills Training Courses",
            describe: "Elevate your communication with the business writing and grammar skills training courses, where precision meets persuasion, and professionalism meets proficiency.",
            img: BusinessWriting,
            Style: {
                backgroundColor: "#99E0F6",
                hoverColor: "linear-gradient(180deg, rgba(44, 108, 128, 0) 0%, #2C6C80 100%)",
                Imgmargin: "20%"
            }
        },
        {
            Title: "Personal and Professional development",
            Heading: "Powerful Presentation Skills",
            describe: "This course is tailored to help individuals enhance their communication abilities, conquer stage fright, and create memorable presentations that leave lasting impressions.",
            img: PresonalDevelopment,
            Style: {
                backgroundColor: "#A0EBB2",
                hoverColor: "linear-gradient(180deg, rgba(45, 140, 68, 0) 0%, #2D8C44 100%)"
            }
        },
        //Management Development
        {
            Title: "Management Development",
            Heading: "Project Management",
            describe: "This comprehensive program provides a deep understanding of project management principles, methodologies, and best practices to enhance management capabilities and drive organisational success.",
            img: "https://drive.google.com/uc?export=view&id=1rFvwg8NhbQ5v8IUa4HjwFDuHTps7yajL",
            Style: {
                backgroundColor: "#CFFDFA",
                hoverColor: "linear-gradient(180deg, rgba(21, 106, 101, 0) 0%, #156A65 100%)",
                Imgmargin: "10%"
            }
        },
        //Leadership & Supervision
        {
            Title: "Leadership And Supervision",
            Heading: "Basics of Supervision",
            describe: "Elevate your leadership abilities with practical insights and strategies for effective team guidance and collaboration.",
            img: "https://drive.google.com/uc?export=view&id=1GtpdPUp4v5Nghw0y_15zeGd4v5wceWR6",
            Style: {
                backgroundColor: "#8EB8F1",
                hoverColor: "linear-gradient(180deg, rgba(44, 69, 103, 0) 0%, #2C4567 100%)",
                Imgmargin: "10%"
            }
        },
        {
            Title: "Leadership And Supervision",
            Heading: "Leadership Skills",
            describe: "Develop effective leadership skills to inspire & lead teams towards achieving organizational objectives.",
            img: "https://drive.google.com/uc?export=view&id=1JgNBlhcSb8vj-eRq5T_sgq-FTusxkVmm",
            Style: {
                backgroundColor: "#EFD781",
                hoverColor: "linear-gradient(180deg, rgba(139, 118, 40, 0) 0%, #8B7628 100%)",
                Imgmargin: "10%"

            }
        },


        //Legal Compliance
        {
            Title: "Legal Compliance",
            Heading: "PCI Compliance",
            describe: "This course is an essential training for businesses to fulfil their legal responsibilities, safeguard sensitive consumer data, and preserve their standing in the marketplace.",
            img: "https://drive.google.com/uc?export=view&id=1NF_LYBdl9RKSgyHdAILc5Pdg6Fm4PXm-",
            Style: {
                backgroundColor: "#BDEFE6",
                hoverColor: "linear-gradient(180deg, rgba(26, 116, 100, 0) 0%, #1A7464 100%)",
                Imgmargin: "20%"

            }
        },
        {
            Title: "Legal Compliance",
            Heading: "SOC2 Compliance",
            describe: "This training is essential for businesses to meet their compliance duties, safeguard sensitive client information, and maintain their reputation in the industry.",
            img: "https://drive.google.com/uc?export=view&id=19seO0Ql6qfxUqxbGuYzA4s4RQMpXOe2E",
            Style: {
                backgroundColor: "#EDD1EA",
                hoverColor: "linear-gradient(180deg, rgba(120, 49, 112, 0) 0%, #783170 100%)",
                Imgmargin: "20%"

            }
        },
        {
            Title: "Legal Compliance",
            Heading: "GDPR Compliance",
            describe: "This course can provide you with a range of abilities that will help you make sure that the standards are followed and personal data is protected, making you a valuable asset to any business that handles the personal data of EU citizens.",
            img: "https://drive.google.com/uc?export=view&id=1uYC97Bkf4i3Utl1V_ctruWZ53fBBPf4c",
            Style: {
                backgroundColor: "#FFEDAD",
                hoverColor: "linear-gradient(180deg, rgba(101, 82, 17, 0) 0%, #655211 100%)",
                Imgmargin: "10%"

            }
        },
        {
            Title: "Legal Compliance",
            Heading: "ISO 27001 - Data Security Compliance",
            describe: "This course can be beneficial for Information security managers, IT specialists, compliance officers, risk managers, and anybody else responsible for managing and securing sensitive data.",
            img: "https://drive.google.com/uc?export=view&id=1R9PTINZohlAy01v6bQZBB5za-cUfBG9y",
            Style: {
                backgroundColor: "#DFB39B",
                hoverColor: "linear-gradient(180deg, rgba(154, 94, 61, 0) 0%, #9A5E3D 100%)",
                Imgmargin: "20%"
            }
        },
        {
            Title: "Legal Compliance",
            Heading: "HIPAA Compliance",
            describe: "In order to complete your legal obligations, protect patient data, and maintain your standing in the medical community, you must comply with HIPAA.",
            img: "https://drive.google.com/uc?export=view&id=1kS_gKcGAtDjBnDthdwTIA7poKOm3vcw2",
            Style: {
                backgroundColor: "#CCEFC0",
                hoverColor: "linear-gradient(180deg, rgba(58, 109, 40, 0) 0%, #3A6D28 100%)",
                Imgmargin: "20%"
            }
        },
        {
            Title: "Legal Compliance",
            Heading: "Anti-Corruption",
            describe: "This course is necessary to ensure that companies operate ethically, legally and responsibly. It helps to maintain a level playing field for all businesses and promotes economic development by reducing corruption and improving transparency.",
            img: "https://drive.google.com/uc?export=view&id=1aYPbynfr02KeNHexu6QB3tFTjZyei6ea",
            Style: {
                backgroundColor: "#D7D7D7",
                hoverColor: "linear-gradient(180deg, rgba(88, 88, 88, 0) 0%, #585858 100%)",
                Imgmargin: "10%"
            }
        },
        {
            Title: "Legal Compliance",
            Heading: "Affirmative Action Training/EEO Training",
            describe: "Gain skills to create inclusive workplaces free from bias, and develop effective communication and conflict resolution abilities.",
            img: "https://drive.google.com/uc?export=view&id=1tkcLc-fFtEsq5fmLfavrUkGrQm0eGLMc",
            Style: {
                backgroundColor: "#DDCCB6",
                hoverColor: "linear-gradient(180deg, rgba(115, 63, 12, 0) 0%, rgba(139, 86, 32, 0) 0.01%, #8B5620 100%)",
                Imgmargin: "10%"
            }
        },

        {
            Title: "Legal Compliance",
            Heading: "Harassment Awareness Training",
            describe: "Learn to prevent and address workplace harassment.",
            img: "https://drive.google.com/uc?export=view&id=1ZBwXq1ka3lOJhGZRSOR6hp4CGdmBZwju",
            Style: {
                backgroundColor: "#E6C0C0",
                hoverColor: "linear-gradient(180deg, rgba(175, 105, 105, 0) 0%, #6C3232 100%)",
                Imgmargin: "20%"
            }
        },

        {
            Title: "Legal Compliance",
            Heading: "Anti-Harassment Prevention Training",
            describe: "Learn how to promote a culture of respect and professionalism by providing Anti-Harassment training.",
            img: "https://drive.google.com/uc?export=view&id=1lAzN81f2yaJsUKelwwgCzltGjMwlw1jH",
            Style: {
                backgroundColor: "#C7FAD7",
                hoverColor: "linear-gradient(180deg, rgba(41, 91, 57, 0) 0%, #295B39 100%)",
                Imgmargin: "20%"
            }
        },
        {
            Title: "Legal Compliance",
            Heading: "PCI Compliance",
            describe: "This course is an essential training for businesses to fulfil their legal responsibilities, safeguard sensitive consumer data, and preserve their standing in the marketplace.",
            img: "https://drive.google.com/uc?export=view&id=1NF_LYBdl9RKSgyHdAILc5Pdg6Fm4PXm-",
            Style: {
                backgroundColor: "#BDEFE6",
                hoverColor: "linear-gradient(180deg, rgba(26, 116, 100, 0) 0%, #1A7464 100%)",
                Imgmargin: "10%"
            }
        },

        //Leadership Skills

        {
            Title: "Leadership Skills",
            Heading: "Adaptability and Agility ",
            describe: "Discover the art of cultivating essential skills to navigate change and lead with flexibility.",
            img: "https://drive.google.com/uc?export=view&id=1fGb-pWuE7dw3Z45RppGlWLRXpOREIzOu",
            Style: {
                backgroundColor: "#FFC7A4",
                hoverColor: "linear-gradient(180deg, rgba(160, 101, 64, 0) 0%, #A06540 100%)",
                Imgmargin: "10%"
            }
        },
        {
            Title: "Leadership Skills",
            Heading: "Employee Motivation",
            describe: "Learn proven leadership strategies to inspire and empower your team for outstanding performance in this course.",
            img: "https://drive.google.com/uc?export=view&id=159lEj-2sTiP02sM24EkRDzL-8HlX8HFw",
            Style: {
                backgroundColor: "#E6B7F4",
                hoverColor: "linear-gradient(180deg, rgba(111, 49, 130, 0) 0%, #6F3182 100%)",
            }
        },

        {
            Title: "Leadership Skills",
            Heading: "Ability of Decision-making",
            describe: "This course equips leaders with the essential skills and knowledge necessary to make informed decisions and skilling with good analytical abilities.",
            img: "https://drive.google.com/uc?export=view&id=1mP_IJd2LPBt8djxOg_7S7jI0_C0QWFMt",
            Style: {
                backgroundColor: "#82C7DD",
                hoverColor: "linear-gradient(180deg, rgba(30, 93, 114, 0) 0%, #1E5D72 100%)",
                Imgmargin: "10%"
            }
        },
        {
            Title: "Leadership Skills",
            Heading: "Critical Thinking, Creativity and Innovation",
            describe: "This course will help you develop critical thinking abilities while also learning the complexities of creating high-performance teams, encouraging a culture of cooperation and trust, and inspiring yourself to reach your full potential.",
            img: "https://drive.google.com/uc?export=view&id=1yvWPkw-KUaI_yFdVVwbS8Xor_t3b4QQ7",
            Style: {
                backgroundColor: "#AAEF8D",
                hoverColor: "linear-gradient(180deg, rgba(61, 125, 34, 0) 0%, #3D7D22 100%)",
                Imgmargin: "10%"
            }
        },
        {
            Title: "Leadership Skills",
            Heading: "Conflict Management",
            describe: "A transformative program designed to equip leaders with the essential skills and strategies to effectively manage and navigate conflicts in the workplace.",
            img: "https://drive.google.com/uc?export=view&id=1-mb2X8DhsZoxp7uXOM5l6C7RT3_ICdOi",
            Style: {
                backgroundColor: "#DAE8FF",
                hoverColor: "linear-gradient(180deg, rgba(66, 93, 137, 0) 0%, #425D89 100%)",
                Imgmargin: "10%"
            }
        },
        {
            Title: "Leadership Skills",
            Heading: "Relationship Management",
            describe: "Unlock the power of authentic connections and inspire excellence through the art of relationship management in leadership.",
            img: "https://drive.google.com/uc?export=view&id=1JIsjOUgykXaUf-aIgjC7216oeBRkrNMD",
            Style: {
                backgroundColor: "#FFE3EC",
                hoverColor: "linear-gradient(180deg, rgba(141, 56, 83, 0) 0%, #8D3853 100%)",
                Imgmargin: "10%"

            }
        },
        {
            Title: "Leadership Skills",
            Heading: "Negotiation in business",
            describe: "Dynamic course designed to provide leaders with the essential skills and strategies to excel in negotiation scenarios within the business world.",
            img: "https://drive.google.com/uc?export=view&id=19Hi79eP7HqYi-7LNKu9wV1kxS2Qz5gfa",
            Style: {
                backgroundColor: "#F6DD78",
                hoverColor: "linear-gradient(180deg, rgba(144, 125, 48, 0) 0%, #917D30 100%)",
                Imgmargin: "10%"
            }
        },
        {
            Title: "Leadership Skills",
            Heading: "Adaptability and Agility ",
            describe: "Discover the art of cultivating essential skills to navigate change and lead with flexibility.",
            img: "https://drive.google.com/uc?export=view&id=1fGb-pWuE7dw3Z45RppGlWLRXpOREIzOu",
            Style: {
                backgroundColor: "#FFC7A4",
                hoverColor: "linear-gradient(180deg, rgba(41, 91, 57, 0) 0%, #295B39 100%)",
            }
        }

    ]




    return (
        <div className='Finance_Content'>
            <div className='header_finance'>
                <Header />
            </div>
            <div className='CourseLibrary_main'>
                    <h2 className='mt-4'>Content Library !</h2>
                    <div className='CourseLibrary mt-2'>
                        <div className='container-inner'>
                            <div class="d-flex justify-content-start titleContainer"><p class="title null">Human Resources</p>
                                <div class="ArrowStyle">
                                    {/* <img src={ArrowBack} width="30px" height="30px" /> */}
                                    <svg width="52" height="22" viewBox="0 0 52 22" fill="none" xmlns3="http://www.w3.org/2000/svg">
                                        <path d="M38.8994 21.1915C38.5111 20.8032 38.3247 20.334 38.3403 19.7839C38.3571 19.2338 38.5596 18.7646 38.9479 18.3763L44.4327 12.8915H33.6088H2.93554C2.38544 12.8915 1.92401 12.7051 1.55124 12.3323C1.17976 11.9608 0.994019 11.5 0.994019 10.95C0.994019 10.3999 1.17976 9.93842 1.55124 9.56565C1.92401 9.19417 2.38544 9.00843 2.93554 9.00843H44.4327L38.8994 3.4751C38.5111 3.08679 38.317 2.62536 38.317 2.09079C38.317 1.55752 38.5111 1.09674 38.8994 0.708432C39.2877 0.320128 39.7491 0.125977 40.2837 0.125977C40.817 0.125977 41.2778 0.320128 41.6661 0.708432L50.5485 9.59089C50.7427 9.78504 50.8805 9.99537 50.9621 10.2219C51.0423 10.4484 51.0824 10.6911 51.0824 10.95C51.0824 11.2088 51.0423 11.4515 50.9621 11.678C50.8805 11.9045 50.7427 12.1149 50.5485 12.309L41.6175 21.24C41.2616 21.5959 40.817 21.7739 40.2837 21.7739C39.7491 21.7739 39.2877 21.5798 38.8994 21.1915Z" fill="#33544E" />
                                    </svg>
                                </div>
                            </div>
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={3}
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper pt-4"
                            >
                                {
                                    ContentCard.slice(0, 9).map((item, index) => {
                                        return (
                                            <SwiperSlide
                                                key={index}
                                                className='slider-Container'
                                            >
                                                <CardUI
                                                    Title={item.Title}
                                                    Heading={item.Heading}
                                                    describe={item.describe}
                                                    Img={item.img}
                                                    Style={item.Style}
                                                />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>

                        <div className='container-inner'>
                            <div class="d-flex justify-content-start titleContainer">
                                <p class="title null">Legal Compliance</p>
                                <div class="ArrowStyle">
                                    {/* <img src={ArrowBack} width="30px" height="30px" /> */}
                                    <svg width="52" height="22" viewBox="0 0 52 22" fill="none" xmlns3="http://www.w3.org/2000/svg">
                                        <path d="M38.8994 21.1915C38.5111 20.8032 38.3247 20.334 38.3403 19.7839C38.3571 19.2338 38.5596 18.7646 38.9479 18.3763L44.4327 12.8915H33.6088H2.93554C2.38544 12.8915 1.92401 12.7051 1.55124 12.3323C1.17976 11.9608 0.994019 11.5 0.994019 10.95C0.994019 10.3999 1.17976 9.93842 1.55124 9.56565C1.92401 9.19417 2.38544 9.00843 2.93554 9.00843H44.4327L38.8994 3.4751C38.5111 3.08679 38.317 2.62536 38.317 2.09079C38.317 1.55752 38.5111 1.09674 38.8994 0.708432C39.2877 0.320128 39.7491 0.125977 40.2837 0.125977C40.817 0.125977 41.2778 0.320128 41.6661 0.708432L50.5485 9.59089C50.7427 9.78504 50.8805 9.99537 50.9621 10.2219C51.0423 10.4484 51.0824 10.6911 51.0824 10.95C51.0824 11.2088 51.0423 11.4515 50.9621 11.678C50.8805 11.9045 50.7427 12.1149 50.5485 12.309L41.6175 21.24C41.2616 21.5959 40.817 21.7739 40.2837 21.7739C39.7491 21.7739 39.2877 21.5798 38.8994 21.1915Z" fill="#33544E" />
                                    </svg>
                                </div>
                            </div>
                            <Swiper
                                spaceBetween={70}
                                slidesPerView={3}
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper pt-4"
                            >
                                {
                                    ContentCard.slice(16, 26).map((item, index) => {
                                        return (
                                            <SwiperSlide
                                                key={index}
                                                className='slider-Container'
                                            >
                                                <CardUI
                                                    Title={item.Title}
                                                    Heading={item.Heading}
                                                    describe={item.describe}
                                                    Img={item.img}
                                                    Style={item.Style}
                                                />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className='container-inner'>
                            <div class="d-flex justify-content-start titleContainer">
                                <p class="title null">Leadership & Supervision</p>
                                <div class="ArrowStyle">
                                    {/* <img src={ArrowBack} width="30px" height="30px" /> */}
                                    <svg width="52" height="22" viewBox="0 0 52 22" fill="none" xmlns3="http://www.w3.org/2000/svg">
                                        <path d="M38.8994 21.1915C38.5111 20.8032 38.3247 20.334 38.3403 19.7839C38.3571 19.2338 38.5596 18.7646 38.9479 18.3763L44.4327 12.8915H33.6088H2.93554C2.38544 12.8915 1.92401 12.7051 1.55124 12.3323C1.17976 11.9608 0.994019 11.5 0.994019 10.95C0.994019 10.3999 1.17976 9.93842 1.55124 9.56565C1.92401 9.19417 2.38544 9.00843 2.93554 9.00843H44.4327L38.8994 3.4751C38.5111 3.08679 38.317 2.62536 38.317 2.09079C38.317 1.55752 38.5111 1.09674 38.8994 0.708432C39.2877 0.320128 39.7491 0.125977 40.2837 0.125977C40.817 0.125977 41.2778 0.320128 41.6661 0.708432L50.5485 9.59089C50.7427 9.78504 50.8805 9.99537 50.9621 10.2219C51.0423 10.4484 51.0824 10.6911 51.0824 10.95C51.0824 11.2088 51.0423 11.4515 50.9621 11.678C50.8805 11.9045 50.7427 12.1149 50.5485 12.309L41.6175 21.24C41.2616 21.5959 40.817 21.7739 40.2837 21.7739C39.7491 21.7739 39.2877 21.5798 38.8994 21.1915Z" fill="#33544E" />
                                    </svg>
                                </div>
                            </div>
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={3}
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper pt-4"
                            >
                                {
                                    ContentCard.slice(14, 16).map((item, index) => {
                                        return (
                                            <SwiperSlide
                                                key={index}
                                                className='slider-Container'
                                            >
                                                <CardUI
                                                    Title={item.Title}
                                                    Heading={item.Heading}
                                                    describe={item.describe}
                                                    Img={item.img}
                                                    Style={item.Style}
                                                />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className='container-inner'>
                            <div class="d-flex justify-content-start titleContainer">
                                <p class="title null">Leadership Skills</p>
                                <div class="ArrowStyle">
                                    {/* <img src={ArrowBack} width="30px" height="30px" /> */}
                                    <svg width="52" height="22" viewBox="0 0 52 22" fill="none" xmlns3="http://www.w3.org/2000/svg">
                                        <path d="M38.8994 21.1915C38.5111 20.8032 38.3247 20.334 38.3403 19.7839C38.3571 19.2338 38.5596 18.7646 38.9479 18.3763L44.4327 12.8915H33.6088H2.93554C2.38544 12.8915 1.92401 12.7051 1.55124 12.3323C1.17976 11.9608 0.994019 11.5 0.994019 10.95C0.994019 10.3999 1.17976 9.93842 1.55124 9.56565C1.92401 9.19417 2.38544 9.00843 2.93554 9.00843H44.4327L38.8994 3.4751C38.5111 3.08679 38.317 2.62536 38.317 2.09079C38.317 1.55752 38.5111 1.09674 38.8994 0.708432C39.2877 0.320128 39.7491 0.125977 40.2837 0.125977C40.817 0.125977 41.2778 0.320128 41.6661 0.708432L50.5485 9.59089C50.7427 9.78504 50.8805 9.99537 50.9621 10.2219C51.0423 10.4484 51.0824 10.6911 51.0824 10.95C51.0824 11.2088 51.0423 11.4515 50.9621 11.678C50.8805 11.9045 50.7427 12.1149 50.5485 12.309L41.6175 21.24C41.2616 21.5959 40.817 21.7739 40.2837 21.7739C39.7491 21.7739 39.2877 21.5798 38.8994 21.1915Z" fill="#33544E" />
                                    </svg>
                                </div>
                            </div>
                            <Swiper
                                spaceBetween={70}
                                slidesPerView={3}
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper pt-4"
                            >
                                {
                                    ContentCard.slice(26, 35).map((item, index) => {
                                        return (
                                            <SwiperSlide
                                                key={index}
                                                className='slider-Container'
                                            >
                                                <CardUI
                                                    Title={item.Title}
                                                    Heading={item.Heading}
                                                    describe={item.describe}
                                                    Img={item.img}
                                                    Style={item.Style}
                                                />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className='container-inner'>
                            <div class="d-flex justify-content-start titleContainer">
                                <p class="title null">Management Development</p>
                                <div class="ArrowStyle">
                                    {/* <img src={ArrowBack} width="30px" height="30px" /> */}
                                    <svg width="52" height="22" viewBox="0 0 52 22" fill="none" xmlns3="http://www.w3.org/2000/svg">
                                        <path d="M38.8994 21.1915C38.5111 20.8032 38.3247 20.334 38.3403 19.7839C38.3571 19.2338 38.5596 18.7646 38.9479 18.3763L44.4327 12.8915H33.6088H2.93554C2.38544 12.8915 1.92401 12.7051 1.55124 12.3323C1.17976 11.9608 0.994019 11.5 0.994019 10.95C0.994019 10.3999 1.17976 9.93842 1.55124 9.56565C1.92401 9.19417 2.38544 9.00843 2.93554 9.00843H44.4327L38.8994 3.4751C38.5111 3.08679 38.317 2.62536 38.317 2.09079C38.317 1.55752 38.5111 1.09674 38.8994 0.708432C39.2877 0.320128 39.7491 0.125977 40.2837 0.125977C40.817 0.125977 41.2778 0.320128 41.6661 0.708432L50.5485 9.59089C50.7427 9.78504 50.8805 9.99537 50.9621 10.2219C51.0423 10.4484 51.0824 10.6911 51.0824 10.95C51.0824 11.2088 51.0423 11.4515 50.9621 11.678C50.8805 11.9045 50.7427 12.1149 50.5485 12.309L41.6175 21.24C41.2616 21.5959 40.817 21.7739 40.2837 21.7739C39.7491 21.7739 39.2877 21.5798 38.8994 21.1915Z" fill="#33544E" />
                                    </svg>
                                </div>
                            </div>
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={3}
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper"
                            >
                                {
                                    ContentCard.slice(13, 14).map((item, index) => {
                                        return (
                                            <SwiperSlide
                                                key={index}
                                                className='slider-Container'
                                            >
                                                <CardUI
                                                    Title={item.Title}
                                                    Heading={item.Heading}
                                                    describe={item.describe}
                                                    Img={item.img}
                                                    Style={item.Style}
                                                />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className='container-inner'>
                            <div class="d-flex justify-content-start titleContainer">
                                <p class="title null">Personal/Professional Development</p>
                                <div class="ArrowStyle">
                                    {/* <img src={ArrowBack} width="30px" height="30px" /> */}
                                    <svg width="52" height="22" viewBox="0 0 52 22" fill="none" xmlns3="http://www.w3.org/2000/svg">
                                        <path d="M38.8994 21.1915C38.5111 20.8032 38.3247 20.334 38.3403 19.7839C38.3571 19.2338 38.5596 18.7646 38.9479 18.3763L44.4327 12.8915H33.6088H2.93554C2.38544 12.8915 1.92401 12.7051 1.55124 12.3323C1.17976 11.9608 0.994019 11.5 0.994019 10.95C0.994019 10.3999 1.17976 9.93842 1.55124 9.56565C1.92401 9.19417 2.38544 9.00843 2.93554 9.00843H44.4327L38.8994 3.4751C38.5111 3.08679 38.317 2.62536 38.317 2.09079C38.317 1.55752 38.5111 1.09674 38.8994 0.708432C39.2877 0.320128 39.7491 0.125977 40.2837 0.125977C40.817 0.125977 41.2778 0.320128 41.6661 0.708432L50.5485 9.59089C50.7427 9.78504 50.8805 9.99537 50.9621 10.2219C51.0423 10.4484 51.0824 10.6911 51.0824 10.95C51.0824 11.2088 51.0423 11.4515 50.9621 11.678C50.8805 11.9045 50.7427 12.1149 50.5485 12.309L41.6175 21.24C41.2616 21.5959 40.817 21.7739 40.2837 21.7739C39.7491 21.7739 39.2877 21.5798 38.8994 21.1915Z" fill="#33544E" />
                                    </svg>
                                </div>
                            </div>
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={3}
                                navigation={false}
                                modules={[Navigation]}
                                className="mySwiper pt-4"
                            >
                                {
                                    ContentCard.slice(9, 13).map((item, index) => {
                                        return (
                                            <SwiperSlide
                                                key={index}
                                                className='slider-Container'
                                            >
                                                <CardUI
                                                    Title={item.Title}
                                                    Heading={item.Heading}
                                                    describe={item.describe}
                                                    Img={item.img}
                                                    Style={item.Style}
                                                />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>

                    </div>
            </div>
            <Footer
                color="#1C5466"
                backgroundColor="#DCFFF9"
            />
        </div>
    )
}

export default CourseLibary