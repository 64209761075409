import React from 'react'
import "./Card.scss"

const CardUI = ({ title, img, des, sections, id }) => {


    const ShowAnimation = () => {
        sections.forEach((section) => {
            const element = document.getElementById(section);
            if (title === section) {
                element.classList.add("AnimationCard");
            } else {
                element.classList.remove("AnimationCard");
            }
        });
    };


    return (
        <div className='Card-Container' style={{ width: id ? "100%" : '' }} id={`${title}`}>
            <h3>{title}</h3>
            <img src={img} alt="card-img" width="100%" height="100%" />
            <p style={{ fontSize: title === "Insights" ? "18px" : '', fontWeight: title === "Insights" ? "500" : '' }}>{des}</p>
            <span onClick={ShowAnimation}>Read More</span>
        </div>
    )
}

export default CardUI