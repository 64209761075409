import React from 'react'
import "./producthub.css";
import createproductimg from "../RProducthub/imgs/createproductimg.svg"
import dotlineimg from "../RProducthub/imgs/dotline.svg"
import consolidated from "../RProducthub/imgs/Group 5377.svg"
import Header from '../../Header/Navbar';
import Footer from '../../Footer/Footer/Footer';
import "./producthub2.css"
import mobprodcons from "../RProducthub/imgs/mobprodcons.svg";
import mobcreateprod from "../RProducthub/imgs/mobcreateprod.svg";


export default function Producthub() {
  return (
    <>
      {/* <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta> */}
      <div className="mainmaindivcontainer">

        <div className="border">
          <Header />
        </div>
        <div className="product-rectangle">
        <div className="product-headding">
          <h1>Product Hub</h1>
        </div>

        <div className="create-product">
          <div className="createproductimg">
            <img src={createproductimg} alt="" className="deskcreateprod"/>
            <img src={mobcreateprod} alt="" className="mobcreateprod"/>
          </div>
        </div>
        <div className="smarter-product">
          <h1>A <span className="bold">Smarter</span> way of learning.</h1>
        </div>

        <div className="three-carrds-product">
          <div class="twocard-product">
            <div class="blok-product">
              <h1 className="cardheadings-product">AI Guides</h1>
              <img src={dotlineimg} alt="p" className="dotlineimgg" />
              <p className="paragra-product">We provide AI guides which help to train your trainees or new joinees in respect of all the technical issues and glitches.</p>
            </div>

            <div class="blok-product">
              <h1 className="cardheadings-product">Automation</h1>
              <img src={dotlineimg} alt="p" className="dotlineimgg" />
              <p className="paragra-product">Automate routine documents like proposals by pulling in live data from Salesforce or any other data source, saving time.</p>
            </div>
          </div>

          <div class="twocard-product">
            <div class="blok-product">
              <h1 className="cardheadings-product">Data-driven Decisions</h1>
              <img src={dotlineimg} alt="p" className="dotlineimgg" />
              <p className="paragra-product">Understand how buyers are engaging with content across the buying cycle. Use insights to accelerate sales.</p>
            </div>
          </div>
        </div>

        <div className="time-savingg-product">
          <h1>Time <span className="boldd">Saving</span> benefits</h1>
        </div>

        <div className="consolidated-platformm">
          <div className="consolidatedd">
            <img src={consolidated} alt="" className="deskprodcons"/>
            <img src={mobprodcons} alt="" className="mobprodcons"/>
          </div>
        </div>
        </div>

        <div className="lastdiv-product">

        </div>

        <div>
          <Footer
            color="#1C5466"
            backgroundColor="linear-gradient(180deg, #FFFDFD 0%, #FFFDE3 100%)"
          />
        </div>
      </div>
    </>
  );
}