import React, { useEffect, useState } from 'react';
import useBlogPosts from '../Hooks/useResults';
import { useParams, useLocation } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { Container, Typography, Box, LinearProgress, CardMedia } from '@material-ui/core';
import Header from "../Header/Navbar"
import Footer from "../Footer/Footer/Footer"
import "./style.scss"

const BlogPost = () => {
    const [post, setPost] = useState();
    const { singlePost, UpdateContent } = useBlogPosts()
    const { postId } = useParams()
    const location = useLocation()
    const [image, setImage] = useState()

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const postPromises = await singlePost(postId); // Map posts to ImageId promises
                //const imagePromises = await ImageId(location.state.id)
                //setImage(imagePromises)
                setPost(postPromises)
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchPost()
    }, [postId]);

    if (!post) {
        return <Box sx={{ width: "100%", height: "500px" }}>
            <div className='header_finance'>
                <Header />
            </div>
            <LinearProgress />
        </Box>;
    }
    const customParseOptions = {
        replace: (node) => {
            // Customize specific elements or attributes
            if (node.type === 'tag' && node.name === 'a') {
                // Modify or customize <a> tags
                return <a href={node.attribs.href} target="_blank" rel="noopener noreferrer">{domToReact(node.children)}</a>;
            }
            // Return the original node for all other elements
            return node;
        }
    };

    const parsedContent = parse(UpdateContent(post[0].content.rendered), customParseOptions);
    // image="https://img.freepik.com/free-photo/lone-tree_181624-46361.jpg?size=626&ext=jpg"

    return (
        <div className='Home_Content'>
            <div className='header_finance'>
                <Header />
            </div>
            <div className='Finance_main'>
                <Container className='mb-5 mt-4'>
                    <Typography variant='h3' dangerouslySetInnerHTML={{ __html: post[0].title.rendered }} className='mb-4 Post-Title' />
                    {/* {
                        error ? <CardMedia
                            style={{ display: "none" }}
                        /> : (
                            <CardMedia
                                style={{ height: "40rem" }}
                                image={image.source_url}
                            />
                        )
                    } */}
                    {Array.isArray(parsedContent) ? parsedContent : [parsedContent]}
                </Container>
            </div>
            <Footer
                color="#1C5466"
                backgroundColor="#D6F5FF"
            />
        </div>
    );
};

export default BlogPost;
