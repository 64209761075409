import React from 'react'
import "./quizzes.css";
import createquizimg from "../RQuizzes/imgs/createquizimg.svg"
import dotline from "../RQuizzes/imgs/dotline.svg"
import deliverimag from "../RQuizzes/imgs/deliver.svg"
import Header from '../../Header/Navbar';
import Footer from '../../Footer/Footer/Footer';
import "./quizzes2.css";
import mobcreatequiz from '../RQuizzes/imgs/mobcreatequiz.svg';
import mobquizcons from '../RQuizzes/imgs/mobquizcons.svg';

export default function Quizzes() {
    
   
    return (
        <>
            
                <div className='border'>
                    <Header />
                </div>

            <div className="quiz-rectangle">
                <div className="quiz-hd" >
                    <h1>Quizzes</h1>
                </div>

                <div className="create-quiz">
                    <div className="quizimg">
                        <img src={createquizimg} alt="" className="deskquiz"/>
                        <img src={mobcreatequiz} alt="" className="mobcreatequiz"/>
                    </div>
                </div>

                <div className="simplify">
                    <h1>Solidify the learnings with simple, fun and interactive <span className="bold">Quizzes</span></h1>
                   
                </div>

                <div className="four-cards">
                    <div class="twos">
                        <div class="bloc">
                            <h1 className="cardheading">Easy Quiz Builder</h1>
                            <img src={dotline} alt="p" className="dotlineimg" />
                            <p className="paragr-quiz">Creating Interactive Quizzes has never been easier. We have made the process so easy that even your kid can make a quiz</p>
                        </div>

                        <div class="bloc">
                            <h1 className="cardheading">Quiz Reports</h1>
                            <img src={dotline} alt="p" className="dotlineimg" />
                            <p className="paragr-quiz">With Quiz Reports you are always updated about the performance of the people through easy-to-use reports</p>
                        </div>
                    </div>

                    <div class="twos">
                        <div class="bloc">
                            <h1 className="cardheading">Improved Visibility</h1>
                            <img src={dotline} alt="p" className="dotlineimg" />
                            <p className="paragr-quiz">Through Quizzes you have the capability to keep an eye on performance by regularly checking the knowledge through quizzes</p>
                        </div>

                        {/* <div class="bloc">
                            <h1 className="cardheading">Better Content Analysis</h1>
                            <img src={dotline} alt="p" className="dotlineimg" />
                            <p className="paragr">It will help you to analyze the content you have already used. Kognics will show you the analytics of the content.</p>
                        </div> */}
                    </div>

                </div>

                <div className="tackles">
                    <h1>The sales team <span className="boldd">tackles</span> more with Kognics.</h1>
                </div>

                <div className="deliver-content">
                    <div className="deliverimg">
                        <img src={deliverimag} alt="" className="deskquizcons"/>
                        <img src={mobquizcons} alt="" className="mobquizcons"/>
                    </div>
                </div>

                <div className="last">

                </div>
                
            </div>
                <div>
                    <Footer
                        color="#1C5466"
                        backgroundColor="linear-gradient(180deg, #FFFDFD 0%, #E8DFF5 100%)"
                    />
                </div>
            
        </>
    );
}
