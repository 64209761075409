import React from 'react'
import Header from '../../Header/Navbar';
import Footer from '../../Footer/Footer/Footer';
import deskcoursefig from '../RCourse/imgs/deskcoursefig.svg';
import deskcreatecourse from '../RCourse/imgs/deskcreatecourse.svg';
import mobcoursefig from '../RCourse/imgs/mobcoursefig.svg';
import mobcreatecourse from '../RCourse/imgs/mobcreatecourse.svg';
import dotlinecourse from '../RCourse/imgs/dotline-course.svg';
import './course.css';
import './course2.css';

export default function Course() {
  return (
    <>

    <div className='border'>
        <Header />
    </div>

    <div className="course-rectangle">

        <div className="course-head" >
            <h1>Course</h1>
        </div>

        <div className="create-course">
            <div className="courseimg">
                    <img src={deskcreatecourse} alt="" className="deskcreatecourse"/>
                    <img src={mobcreatecourse} alt="" className="mobcreatecourse"/>
            </div>
        </div>

        <div className="searching">  
            <h1>With ChatGPT and simple click and drag, now create immersive courses. <span className="bold"></span> <br />
            <p className="hours45"> Learning should be engaging.<span className="bold"></span></p> </h1>  
        </div>

        <div className="four-cards-course">

          <div class="two-cards-course">

            <div class="block-course">
              <h1 className="cardheading-course">Move out of old tech and build more immersive courses</h1>
              <img src={dotlinecourse} alt="p" className="dotlinecourse" />
              <p className="paragraph-course">Learning should be fun and interactive. With Kognics Course Builder, make beautiful immersive courses that makes learning fun</p>
            </div>

            <div class="block-course">
              <h1 className="cardheading-course">Include Assets, Files and Quizzes</h1>
              <img src={dotlinecourse} alt="p" className="dotlinecourse" />
              <p className="paragraph-course">Using your already uploaded assets, files and quizzes, create courses that will help your reps get trained faster than before.</p>
            </div>

          </div>

          <div class="two-cards-course">
                        
            <div class="block-course">
              <h1 className="cardheading-course">ChatGPT for your making your trainer’s life easy</h1>
              <img src={dotlinecourse} alt="p" className="dotlinecourse" />
              <p className="paragraph-course">With our ChatGPT Integration, now make your trainer’s life as chatgpt will help them get high quality content with simple questions</p>
            </div>

            <div class="block-course">
              <h1 className="cardheading-course">Get it all in one place.</h1>
              <img src={dotlinecourse} alt="p" className="dotlinecourse" /> 
              <p className="paragraph-course">CRM integrations will help sales managers and sales teams, so that you never need to leave your platform again.</p>
            </div>

          </div>

        </div>
  
        <div className="tackles-course">
                <h1>The sales team <span className="boldd">tackles</span> more with Kognics.</h1>
        </div>

        <div className="coursefig">
          <div className="coursefigimg">
            <img src={deskcoursefig} alt="" className="deskcoursefig"/>
            <img src={mobcoursefig} alt="" className="mobcoursefig"/>
          </div>
        </div>

        <div className="last-course">

        </div>
        <Footer
            color="#1C5466"
            backgroundColor="#DBFFF8"
        />
    </div>
</>
  );
}
