import React from 'react'
import PlatFrom from "../../Images/NewHome/platform-all.svg"
import Courses from "../../Images/NewHome/Courses.svg"
import Feedback from "../../Images/NewHome/Feedback.svg"
import LearningPath from "../../Images/NewHome/LearningPath.svg"
import ProductHub from "../../Images/NewHome/producthub-commingsoon.svg"
import Quizzes from "../../Images/NewHome/Quizzes.svg"
import CardUI from './CardUI/Card'
import { Link } from 'react-router-dom'

const PlatfromForAll = ({ title, aboutdata, id, paddingTop }) => {
    return (
        <div className='platfrom-container' style={{ paddingTop: paddingTop ? paddingTop : '' }}>
            <div className='heading-Conatiner'>
                <div className='platfrom-heading' style={{ background: id ? '#C8F2FF' : '' }}>
                    <h2 style={{ color: id ? '#125064' : '', fontWeight: id ? 400 : '' }}>{title ? title : 'A Platform for all.'}</h2>
                </div>
            </div>
            <div className='platfrom-containerInner' style={{ paddingTop: id ? "10rem" : '', paddingBottom: id ? "10rem" : '' }}>
                <div className='platfrom-img-container'>
                    <img src={PlatFrom} alt='platfromImg' width="100%" height="100%" />
                    <div className='Platfroms'>
                        <div className='Platfroms-topItems'>
                            <div className='platfromItem Courses'>
                                {
                                    title ?
                                        <div className='about-box-finance'>
                                            <CardUI
                                                id={id}
                                                title={aboutdata[0].title}
                                                img={aboutdata[0].image}
                                                des={aboutdata[0].des}
                                                sections={["Finance", "Software", "Healthcare", "Retail", "Manufacturing"]}

                                            />
                                        </div>
                                        :
                                        <Link to="/Courses">
                                            <img src={Courses} alt="course" width="100%" height="100%" />
                                        </Link>
                                }
                            </div>
                            <div className='platfromItem Feedback'>
                                {title ?
                                    <div className='about-box-software'>
                                        <CardUI
                                            id={id}
                                            title={aboutdata[1].title}
                                            img={aboutdata[1].image}
                                            des={aboutdata[1].des}
                                            sections={["Finance", "Software", "Healthcare", "Retail", "Manufacturing"]}
                                        />
                                    </div>
                                    :
                                    <Link to="/Feedback">
                                        <img src={Feedback} alt="Feedback" width="100%" height="100%" />
                                    </Link>
                                }
                            </div>
                        </div>
                        <div className='Platfroms-bottomItems'>
                            <div className='platfromItem LearningPath'>
                                {title ?
                                    <div className='about-box-healthCare'>
                                        <CardUI
                                            id={id}
                                            title={aboutdata[2].title}
                                            img={aboutdata[2].image}
                                            des={aboutdata[2].des}
                                            sections={["Finance", "Software", "Healthcare", "Retail", "Manufacturing"]}
                                        />
                                    </div> :

                                    <Link to="/Learning-Path">
                                        <img src={LearningPath} alt="Learning Path" width="100%" height="100%" />
                                    </Link>
                                }
                            </div>
                            <div className='platfromItem Quizzes'>
                                {title ?
                                    <div className='about-box-retail'>
                                        <CardUI
                                            id={id}
                                            title={aboutdata[3].title}
                                            img={aboutdata[3].image}
                                            des={aboutdata[3].des}
                                            sections={["Finance", "Software", "Healthcare", "Retail", "Manufacturing"]}
                                        /> </div>
                                    :
                                    <Link to="/Quizzess">
                                        <img src={Quizzes} alt="Quizzes" width="100%" height="100%" />
                                    </Link>
                                }
                            </div>
                            <div className='platfromItem ProductHub'>
                                {title ?
                                    <div className='about-box-manufacturing'>
                                        <CardUI
                                            id={id}
                                            title={aboutdata[4].title}
                                            img={aboutdata[4].image}
                                            des={aboutdata[4].des}
                                            sections={["Finance", "Software", "Healthcare", "Retail", "Manufacturing"]}
                                        /> </div> :
                                    <Link to="/">
                                        <img src={ProductHub} alt="Product Hub" width="100%" height="100%" />
                                    </Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlatfromForAll