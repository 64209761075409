import React, { useState, useEffect } from 'react';
import ChatGptMobile from "../../../Images/NewHome/ChatGPT-mobile-1.svg"
import ContentImg from "../../../Images/NewHome/sliderImage-Home.svg"
import "./ImageSlider.css"


const ImageSlider = () => {
    const images = [
        { src: ContentImg },
        { src: ChatGptMobile }
    ];

    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    const goToImage = (index) => {
        setCurrentImage(index);
    };

    return (
        <div className="image-slider">

            {/* <div className='image-slider-container'>
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image.src}
                        alt={`Image ${index + 1}`}
                        className={index === currentImage ? 'activeImg' : ''}
                    />
                ))}
            </div>
            
            <div className="pagination">
                {images.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => goToImage(index)}
                        className={index === currentImage ? 'activeImg' : ''}
                    >
                    </button>
                ))}
            </div> */}

            <h2>Training should be a Cakewalk</h2>



        </div>
    );
};

export default ImageSlider;
