import React from 'react'
import Box1 from './Box1'
import Box2 from './Box2'
import Box3 from './Box3'
import Box4 from './Box4'
import PopUpCat from "../../Images/fox.svg";

function Footer({ color, backgroundColor }) {
  return (
    <div className='footer-container'>
      <div className='footer' style={{ background: backgroundColor, color: color }}>
        <Box1 color={color} />
        <div className='contact'>
          <Box2 color={color} />
          <Box3 color={color} />
          <Box4 color={color} />
        </div>
        <div className='popFooterCat'>
          <img src={PopUpCat} alt='remove-cat' width="100%" height="100%"/>
        </div>
      </div>
      <div className='copyright' style={{ background: "#1C5466" }}>
        <div><i className="fa-regular fa-copyright"></i>&nbsp; KOGNICS 2022. ALL RIGHTS RESERVED</div>
      </div>
    </div>
  )
}

export default Footer