import React from 'react'
import logo from "../Images/logo.svg"
import "./Card.scss"


const CardUI = ({ Title, Heading, describe, Img, Style }) => {

    return (
        <div className='Card' style={{ backgroundColor: Style.backgroundColor, width: Title === "Legal Compliance" || Title === "Leadership Skills" ? "33rem" : '' }}>
            <div className='card-inner'>
                <h4>{Title}<img src={logo} alt='logo' /></h4>
                <h3>{Heading}</h3>
                <p>{describe}</p>
            </div>
            <div className='image-container'>
                <img src={Img} alt='human-resources' width="100%" height="100%" style={{ marginLeft: Style.Imgmargin ? Style.Imgmargin : '' }} />
            </div>
            <div className='hoverText-container' style={{ background: Style.hoverColor }}>
                <p href='#' target='_blank'>Get it Now</p>
            </div>
        </div>
    )
}

export default CardUI