import React from 'react'
import { Link } from 'react-router-dom'

function Box3({color}) {
  return (
    <div className='box3'>
      <div className='bold'>
        Support
      </div>
      <div className='all'>
        {/* <div style={{ display: "none" }}><a className='textWhite'>FAQs</a></div> */}
        <div><Link className='textWhite' style={{color:color}} to="/Privacy-Policy">Privacy Policy</Link></div>
        <div><Link className='textWhite' style={{color:color}} to="/Terms-Condition">Terms of Use</Link></div>
      </div>
    </div>
  )
}

export default Box3